import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';


export function transposeData(data) {
  return Object.keys(data[0]).map(key => {
    return data.map(item => item[key]);
  });
}

export const handleFilterChange = (setFilter) => (newFilter) => {
  if(newFilter === '-1'){
    setFilter(null);
  }else{
    setFilter(newFilter);
  }
};

export const months = ['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'];

export const doDataExport = (chartTitle, idName, className, filters, padding = 5) => {
  let wrapper = document.getElementById(idName);
  if (wrapper) {
    wrapper.className = className;
  }
  html2canvas(wrapper).then((canvas) => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const aspectRatio = canvas.width / canvas.height;
    let scale = 1;
    if (aspectRatio > 1) { // Landscape orientation
      scale = pdfWidth / canvas.width;
    } else { // Portrait orientation
      scale = pdfHeight / canvas.height;
    }

    const scaledWidth = canvas.width * scale;
    const scaledHeight = canvas.height * scale;
    const xPos = (pdfWidth - scaledWidth) / 2;
    const yPos = (pdfHeight - scaledHeight) / 2;

    // Add image with padding
    pdf.addImage(canvas, 'PNG', xPos + padding, yPos + padding, scaledWidth - (2 * padding), scaledHeight - (2 * padding));

    if (filters.length > 0) {
      // Add formatted filters as text to the PDF
      const lineHeight = 5;
      const fontSize = 8;

      // Calculate starting position for filters text
      let filterYPos = yPos + scaledHeight + padding + 10;

      // Check if filters exceed the remaining space on the page
      if (filterYPos + filters.length * (lineHeight + padding) > pdfHeight) {
        pdf.addPage(); // Add a new page if needed
        filterYPos = 10; // Reset Y position for filters text
      }

      filters.forEach((filter, index) => {
        const textXPos = 10;
        const textYPos = filterYPos + index * (lineHeight + padding); // Adjusted for additional margin

        // Wrap text if it overflows
        const maxWidth = pdfWidth - textXPos * 2;
        const splitText = pdf.splitTextToSize(`${filter.key}: ${filter.value?.length > 0 ? filter.value : 'N/A'}`, maxWidth);
        pdf.setFontSize(fontSize);
        pdf.text(splitText, textXPos, textYPos);
      });
    }

    pdf.save(`${chartTitle}${new Date().toISOString()}.pdf`);
    if (wrapper) {
      wrapper.className = className;
    }
  });
};



