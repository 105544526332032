import Select from 'react-select';
import DropDownSelect from '../CustomFilters/DropdownSelect';
import React from 'react';
import { calculationData, customStyles,  demandClassOptions,  fiscalMonthOptions,  isMonday, periodTypeOptions, productOptions, quarterOptions, regionOptions, yearOptions } from './dropdownData';
import { PeriodTypeEnum } from './periodTypeEnum';
import DatePicker from 'react-datepicker';

export const SelectedPeriodForecastAccuracyFilter = (props) => {

  const { view, periodType, setPeriodType, yearFilter, setYearFilter, forecastWeek, setForecastWeek, fiscalMonth, setFiscalMonth, quarter, setQuarter, handleProductFilterChange, productCategoriesData, demandClassData,
    regionFilter, handleRegionFilterChange, demandClassFilter, handleDemandClassFilterChange, productCategoryFilter, setCalculationType, calculationType } = props;

  const dashboardView = view == 'dashboard';
  
  return (
    <div className='emea-filter-container'
      style={{ width: '100%' }}>
      <DropDownSelect
        label="Period Type"
        options={periodTypeOptions}
        selectedValue={periodType}
        hasAllFilter={false}
        onChange={(e) => setPeriodType(e.target.value)} />
      {periodType == PeriodTypeEnum.WEEKCOMMENCING && 
      <label>
            Week Commencing
        <DatePicker
          selected={forecastWeek}
          onChange={(date) => setForecastWeek(date)}
          filterDate={isMonday}
          dateFormat="dd/MM/yyyy"
          placeholderText="Select a monday"
        />
      </label>
      }
      {periodType == PeriodTypeEnum.FISCALMONTH &&
       <DropDownSelect
         label="Fiscal Month"
         options={fiscalMonthOptions}
         selectedValue={fiscalMonth}
         hasAllFilter={false}
         onChange={(e) => setFiscalMonth(e.target.value)} />
      }
      {periodType == PeriodTypeEnum.QUARTER && 
      <DropDownSelect
        label="Quarter"
        options={quarterOptions}
        selectedValue={quarter}
        hasAllFilter={false}
        onChange={(e) => setQuarter(e.target.value)} />
      }
      {(periodType == PeriodTypeEnum.FISCALMONTH || periodType == PeriodTypeEnum.QUARTER || periodType == PeriodTypeEnum.YEARTODATE) &&
  <DropDownSelect
    label="Year"
    options={yearOptions}
    selectedValue={yearFilter}
    hasAllFilter={false}
    onChange={(e) => setYearFilter(e.target.value)} />
      }

      <label>
            Regions
        <Select
          isMulti
          placeholder="All Regions"
          styles={customStyles}
          options={regionOptions}
          value={regionFilter}
          onChange={handleRegionFilterChange}
        />
      </label>
      <label>
            Demand Classes
        <Select
          isMulti
          placeholder="All Demand Classes"
          styles={customStyles}
          options={demandClassOptions(demandClassData)}
          value={demandClassFilter}
          onChange={handleDemandClassFilterChange}
        />
      </label>
      <DropDownSelect
        label="Error Calculation"
        options={calculationData}
        selectedValue={calculationType}
        hasAllFilter={false}
        onChange={(e) => setCalculationType(e.target.value)} />
      {dashboardView &&
      <><label>
          Product Categories
        <Select
          isMulti
          placeholder="All Categories"
          styles={customStyles}
          options={productOptions(productCategoriesData)}
          value={productCategoryFilter}
          onChange={handleProductFilterChange} />
      </label></>
      }
    </div>
  );
};