import React, { useState, useEffect, useMemo } from 'react';
import forecastAccuracyActions from '../../../actions/forecastAccuracyActions';
import { connect } from 'react-redux';
import CustomLineChart from '../CustomCharts/CustomLineChart';
import chroma from 'chroma-js';
import 'react-datepicker/dist/react-datepicker.css';

const MarketForecastAccuracy = (props) =>{
  const { productCategoryFilter, demandClassFilter, calculationType, regionFilter, periodType, fiscalMonth, quarter, yearFilter, forecastWeekDate } = props;
  const [localStateRegion, setLocalStateRegion] = useState([]);

  useEffect(() => {
    if (!props.loading){
      props.getMarketForecastAccuracyData(periodType, fiscalMonth, quarter, yearFilter,
        new Date(forecastWeekDate.getTime() - forecastWeekDate.getTimezoneOffset() * 60000).toISOString(),
        productCategoryFilter ? productCategoryFilter.map(x => x.value) : null,
        calculationType,
        demandClassFilter ? demandClassFilter.map(x => x.value) : null,
        regionFilter ? regionFilter.map(x => x.value) : null,
      );
    }
  }, [periodType, fiscalMonth, quarter, yearFilter, forecastWeekDate, productCategoryFilter, demandClassFilter, calculationType]);

  useEffect(() => {
    // Call the method only if regionFilter has changed
    if (JSON.stringify(regionFilter) !== JSON.stringify(localStateRegion)){
      props.getMarketForecastAccuracyData(
        periodType, fiscalMonth, quarter, yearFilter,
        new Date(forecastWeekDate.getTime() - forecastWeekDate.getTimezoneOffset() * 60000).toISOString(),
        null,
        calculationType,
        null,
        regionFilter ? regionFilter.map(x => x.value) : null,
      );
      setLocalStateRegion(regionFilter);
    }
  }, [regionFilter]);  

  const { transformedData, lines } = useMemo(() => {
    if (!props.marketForecastAccuracyData) {
      return { transformedData: [], lines: [] };
    }

    const newData = props.marketForecastAccuracyData.map((lag) => ({
      name: lag.name,
      ...lag.demandClasses,
    }));

    const getDistinctColors = (count) => chroma.scale('Set2').colors(count);

    const allDemandClasses = props.marketForecastAccuracyData.reduce((demandClasses, lagData) => {
      if (Object.keys(lagData.demandClasses).length > 0) {
        Object.keys(lagData.demandClasses).forEach(dc => {
          if (!demandClasses.includes(dc)) {
            demandClasses.push(dc);
          }
        });
      }
      return demandClasses;
    }, []);

    const distinctColors = getDistinctColors(allDemandClasses.length);

    const lines = allDemandClasses.map((dc, index) => ({
      dataKey: dc,
      strokeColor: distinctColors[index % distinctColors.length],
    }));

    return { transformedData: newData, lines };
  }, [props.marketForecastAccuracyData]);

  return <>
    <div
      className='chart-container'
      style={{ display: 'block' }}>
      {props.loading ? (
        <div className="app-spinner">
          <span className="spinner" />
        </div>
      ) : (
        <>        
          <CustomLineChart data={transformedData}
            lines={lines}
            chartTitle={'Forecast Accuracy (%)'}
            showsPercentage={true}
            dataKey={'name'}
            width={'100%'} /></>
      )}
    </div>
  </>;
};

const mapStateToProps = (state) => {
  return {
    marketForecastAccuracyData: state.forecastAccuracy.marketForecastAccuracyData,
    loading: state.forecastAccuracy.marketForecastLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMarketForecastAccuracyData: (periodType, fiscalMonth, quarter, yearFilter, forecastWeek, productCategories, calculationType, demandClasses, regionIds) => dispatch(forecastAccuracyActions.getMarketForecastAccuracyData(periodType, fiscalMonth, quarter, yearFilter, forecastWeek, productCategories, calculationType, demandClasses, regionIds)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketForecastAccuracy);