
import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import forecastAccuracyActions from '../../../actions/forecastAccuracyActions';
import { connect } from 'react-redux';
import ForecastTile from '../Common/forecastTile';

const ForecastBiasYtd = (props) => {
  const { productCategoryFilter, demandClassFilter, calculationType, regionFilter } = props;
  const [localStateRegion, setLocalStateRegion] = useState([]);

  useEffect(() => {
    if (!props.loading){
      props.getForecastBiasYtdData(regionFilter, demandClassFilter, productCategoryFilter, calculationType);
    }
  }, [demandClassFilter, productCategoryFilter, calculationType]);

  useEffect(() => {
    // Call the method only if regionFilter has changed
    if (JSON.stringify(regionFilter) !== JSON.stringify(localStateRegion)){
      props.getForecastBiasYtdData(regionFilter, null, null, calculationType);
      setLocalStateRegion(regionFilter); // Update the previous region filter
    }
  }, [regionFilter]);  
  
  const isValid = props.forecastBiasYtdData !== undefined; 

  return (
    <div className='carousel-container'>
      {!isValid && (<div className="app-spinner">
        <span className="spinner" />
      </div>)}
      <div>
        <div className='ytd-forecast-wrapper'>
          {props.forecastBiasYtdData && props.forecastBiasYtdData.map(f => 
            <ForecastTile key={f.title}
              title={f.title}
              value={f.value?.toFixed(2)} 
              positive={f.positive}
              bias={f.bias}
              differenceMetric={f.differenceValue?.toString()} 
            />,
          )}
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    loading: state.forecastAccuracy.biasYtdLoading,
    forecastBiasYtdData: state.forecastAccuracy.forecastBiasYtdData,
  };
};
 

const mapDispatchToProps = (dispatch) => {
  return {
    getForecastBiasYtdData: (regionIds, demandClasses, categories, calculationType) => dispatch(forecastAccuracyActions.getForecastBiasYtdData(regionIds, demandClasses, categories, calculationType)),
  };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(ForecastBiasYtd);