/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef } from 'react';
import Table from '../CustomTables/CustomTable';
import CustomBarChart from '../CustomCharts/CustomBarChart';
import forecastPerformanceDashboardActions from '../../../actions/forecastPerformanceDashboardActions';
import { connect } from 'react-redux';
import { TimeFilterEnum } from '../Common/Enums/TimeFilterEnum';
import './emergencyOrders.scss';
import { doDataExport } from '../DemandAndSupply/CarouselCharts/CommonCarouselFunctions';
import MarketMultiSelectFilter from '../CustomFilters/MultiSelect/MarketMultiSelectFilter';
import PeriodMultiSelectFilter from '../CustomFilters/MultiSelect/PeriodMultiSelectFilter';
import { ExportChart } from '../Common/Enums/ExportChart';

const EmergencyOrders = (props) =>{

  const tableHeaders = ['Market', 'Order Count'];

  const bars = [
    { dataKey: 'OrderCount', strokeColor: '#8884d8' },
  ];
  const marketIds = props.mbuData.map(market => market.marketId);
  const [selectedMarkets, setSelectedMarkets] = useState(marketIds);
  const [periodFilter, setPeriodFilter] = useState(TimeFilterEnum.YTD);
  const [selectedMonths, setSelectedMonths] = useState([]);


  useEffect(() => {
    props.getEmergencyOrderChartData(periodFilter, selectedMonths, selectedMarkets); 
  }, []);

  let mbuEmergencyOrderList = Array.isArray(props.mbuData) && props.mbuData.map(mbu => {
    let data = Array.isArray(props.emergencyOrdersData.mbuEmergencyOrderList) && 
               props.emergencyOrdersData.mbuEmergencyOrderList.find(sd => sd.marketId === mbu.marketId);
    return {
      name: mbu.name.replace('Starbucks', ''),
      OrderCount: data ? data.count : 0,
    };
  });

  const monthsArr = ['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'];

  let monthlyEmergencyOrderList = Array.isArray(monthsArr) && monthsArr.map(month => {
    let data = Array.isArray(props.emergencyOrdersData.monthlyEmergencyOrderList) && 
               props.emergencyOrdersData.monthlyEmergencyOrderList.find(sd => sd.name === month);
    return {
      name: month,
      OrderCount: data ? data.count : 0,
    };
  });

  const handlePeriodFilterChange = (newFilter) => {
    setPeriodFilter(newFilter);
  };

  const handleMonthsChange = (selectedMonths) => {
    setSelectedMonths(selectedMonths);
  };

  const handleMarketFilterChange = (selectedMarkets) => {
    setSelectedMarkets(selectedMarkets);
  };

  const handleSearchButtonClick = () => {
    props.getEmergencyOrderChartData(periodFilter, selectedMonths, selectedMarkets); 
  };

  const handleClearFilterClick = () => {
    var newPeriod = TimeFilterEnum.YTD;
    var newMonths = [];
    setSelectedMarkets(marketIds);
    setPeriodFilter(newPeriod);
    setSelectedMonths(newMonths);
    props.getEmergencyOrderChartData(newPeriod, newMonths, marketIds); 
  };

  
  function transposeData(data) {
    if (!Array.isArray(data)) {
      throw new TypeError('Data must be an array');
    }
    if (data.length === 0) {
      return [];
    }
    const keys = Object.keys(data[0]);
    if (!keys.every(key => data.every(item => key in item))) {
      throw new TypeError('All objects in data must have the same keys');
    }
    return keys.map(key => data.map(item => item[key]));
  }
   
  const handleExportSummary = () => {
    props.exportRawData(ExportChart.EmergencyOrder,periodFilter,selectedMonths,selectedMarkets);
  };

  const months = [
    { key: 10, value: 'October' },
    { key: 11, value: 'November' },
    { key: 12, value: 'December' },
    { key: 1, value: 'January' },
    { key: 2, value: 'February' },
    { key: 3, value: 'March' },
    { key: 4, value: 'April' },
    { key: 5, value: 'May' },
    { key: 6, value: 'June' },
    { key: 7, value: 'July' },
    { key: 8, value: 'August' },
    { key: 9, value: 'September' },
  ];  

  const filters = [
    { key: 'Period', value: periodFilter },
    { key: 'Selected Months', value: selectedMonths.map(id => months.find(month => month.key === id)?.value).join(', ') },
    { key: 'Selected Markets', value: selectedMarkets.map(id => props.mbuData?.find(m => m.marketId === id)?.name).join(', ') },
  ];

  return <><div>

    <div className="group-details accordion download-container summary forecast-export">
      <div className="accordion-header">
        <h3>Download Summary</h3>
      </div>
      <div className="accordion-body horizontal-flex order-fields">
        <div>
          <button
            className="action export"
            type="button"
            title="Export"
            disabled={false}
            onClick={() => handleExportSummary()}
          >
            <div
              className="icon icon-download-light"
              alt="Export"
            />
          </button>
        </div>
      </div>
    </div>
    <div className='emergency-filter-container'>
      <MarketMultiSelectFilter 
        mbuList={props.mbuData}
        selectedMarkets={selectedMarkets}
        onMarketFilterChange={handleMarketFilterChange}/>
      <PeriodMultiSelectFilter        
        periodFilter={periodFilter}
        selectedMonths={selectedMonths}
        onPeriodFilterChange={handlePeriodFilterChange}
        onSelectedMonths={handleMonthsChange}
        orderMetrics={false} />
    </div>

    <div className="kpi-filter-action">
      <button
        type="button"
        className="action"
        onClick={() => handleSearchButtonClick()}
      >
          Search
      </button>
      {<button
        type="clear"
        className="action default"
        onClick={() => handleClearFilterClick()}
      >
          Clear Filter
      </button>}
    </div>

  </div>
  <div id='emergency-orders'
    className='emergency-dashboard'>

    <div id='market-emergency-orders'  
      className='emergency-order-chart-container large-width-container'>
      <CustomBarChart data={mbuEmergencyOrderList} 
        chartTitle={'Number of emergency order for all markets'}
        bars={bars} 
        width={'100%'}/>
    </div>

    <div id='monthly-emergency-orders' 
      className='emergency-order-chart-container small-width-container'>
      <CustomBarChart data={monthlyEmergencyOrderList} 
        chartTitle={'Number of emergency order for selected market monthly'}
        bars={bars} 
        width={'100%'} />
    </div>
  </div>

  <div className='export-button-style'>
    <button type="button"
      className="action export"
      onClick={() => doDataExport('EO_', 'emergency-orders', 'emergency-dashboard', filters)}>Export Chart</button>
  </div>
  <div className='emergency-table-container'>
    <Table headers={tableHeaders} 
      transposed={true}
      data={transposeData(mbuEmergencyOrderList)} />
  </div>

  </>;
};

const mapStateToProps = (state) => {
  return {
    emergencyOrdersData: state.forecastPerformanceDashboard.emergencyOrdersData,
  };
};
 

const mapDispatchToProps = (dispatch) => {
  return {
    getEmergencyOrderChartData: (timeFilter, selectedMonths, selectedMarkets)=> 
      dispatch(forecastPerformanceDashboardActions.getEmergencyOrderChartData(timeFilter, selectedMonths, selectedMarkets)),
    exportRawData: (exportChart, timeFilter, selectedMonths, selectedMarkets) => 
      dispatch(forecastPerformanceDashboardActions.exportRawData(exportChart, timeFilter, selectedMonths, selectedMarkets)),
  };
};
 

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyOrders);