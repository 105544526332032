import React, { useEffect, useState } from 'react';
import forecastAccuracyActions from '../../../actions/forecastAccuracyActions';
import { connect } from 'react-redux';

const LeaderboardMostAccurateMarket = (props) => {
  const { viewEntry, demandClassQuery, demandClassFilter, regionFilter, periodType, fiscalMonth, quarter, yearFilter, forecastWeekDate, setDemandClassQuery } = props;
  const [localStateRegion, setLocalStateRegion] = useState([]);

  useEffect(() => {
    props.getMostAccurateMarketsLeaderboard(periodType, fiscalMonth, quarter, yearFilter, new Date(forecastWeekDate.getTime() - forecastWeekDate.getTimezoneOffset() * 60000).toISOString(), regionFilter, demandClassFilter, demandClassQuery);
  }, [periodType, fiscalMonth, quarter, yearFilter, forecastWeekDate, demandClassFilter, demandClassQuery]);
  
  useEffect(() => {
    // Call the method only if regionFilter has changed
    if (JSON.stringify(regionFilter) !== JSON.stringify(localStateRegion)){
      props.getMostAccurateMarketsLeaderboard(new Date(forecastWeekDate.getTime() - forecastWeekDate.getTimezoneOffset() * 60000).toISOString(), regionFilter, null, null);
      setLocalStateRegion(regionFilter); // Update the previous region filter
    }
  }, [regionFilter]);  

  return (
    <>
      <>  
        <div className="table-body">
          <div style={{ display: 'flex' }}>
            <input
              type="text"
              value={demandClassQuery}
              onChange={(e) => setDemandClassQuery(e.target.value)}
              placeholder="Search demand class..." />
          </div>
          <table className={'table-display-mode-4'}>
            <thead>
              <tr>
                <th>Rank</th>
                <th>Demand Class</th>
                <th>Region</th>
                <th>WMAPE (Order Value)</th>
                <th>MAPE (Order Value)</th>
                <th>Trend</th>
                <th />
              </tr>
            </thead>
            {props.loading ? (
              <div className="app-spinner">
                <span className="spinner" />
              </div>
            ) : (
              <tbody>
                {props.mostAccurateMarketsData && props.mostAccurateMarketsData.entries && props.mostAccurateMarketsData.entries.length > 0  ? (
                  <>
                    {props.mostAccurateMarketsData.entries.map(e => 
                      <tr key={e.rank}>
                        <td><span className={`leaderboard-rank rank${e.rank}`}>{e.rank}</span></td>
                        <td>{e.demandClassName}</td>
                        <td>{e.region}</td>
                        <td>{e.mwape}%</td>
                        <td>{e.mape}%</td>
                        <td>
                          <span className='difference-metric'
                            style={{ color: e.positive ? 'green' : 'red' }}>{e.positive ? '+' : ''}{e.trend}%</span></td>
                        <td className="right-cell"
                          style={{ width: '50px' }}>
                          <button type="button"
                            className='leaderboard-button'
                            style={{ width: '30px', height: '30px' }}
                            onClick={() => viewEntry(e.market)}>
                            <img src='/assets/icons/right-arrow.svg'
                              style={{ verticalAlign: 'top' }}
                              alt="arrow" />
                          </button>
                        </td>
                      </tr>,
                    )}
                  </>
                ) : (
                  <tr><td colSpan={7}
                    style={{ padding: '10px', textAlign: 'center' }}>No data.</td></tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    mostAccurateMarketsData: state.forecastAccuracy.mostAccurateMarketsData,
    loading: state.forecastAccuracy.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMostAccurateMarketsLeaderboard: (periodType, fiscalMonth, quarter, yearFilter, forecastWeek, regionIds, demandClasses, query) => dispatch(forecastAccuracyActions.getMostAccurateMarketsLeaderboard(periodType, fiscalMonth, quarter, yearFilter, forecastWeek, regionIds, demandClasses, query)),
  };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardMostAccurateMarket);