import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import forecastAccuracyActions from '../../../actions/forecastAccuracyActions';
import { connect } from 'react-redux';
import ForecastTile from '../Common/forecastTile';
import 'react-datepicker/dist/react-datepicker.css';

const CurrentPeriodForecastAccuracy = (props) => {
  const { productCategoryFilter, demandClassFilter, calculationType, regionFilter, periodType, fiscalMonth, quarter, yearFilter, forecastWeekDate } = props;
  const [localStateRegion, setLocalStateRegion] = useState([]);

  useEffect(() => {
    if (!props.loading){
      props.getCurrentPeriodAccuracyData(periodType, fiscalMonth, quarter, yearFilter, new Date(forecastWeekDate.getTime() - forecastWeekDate.getTimezoneOffset() * 60000).toISOString(), regionFilter, demandClassFilter, productCategoryFilter, calculationType);
    }
  }, [periodType, fiscalMonth, quarter, yearFilter, forecastWeekDate, demandClassFilter, productCategoryFilter, calculationType]);

  useEffect(() => {
    // Call the method only if regionFilter has changed
    if (JSON.stringify(regionFilter) !== JSON.stringify(localStateRegion)){
      props.getCurrentPeriodAccuracyData(
        periodType, fiscalMonth, quarter, yearFilter, new Date(forecastWeekDate.getTime() - forecastWeekDate.getTimezoneOffset() * 60000).toISOString(), regionFilter, null, null, calculationType);
      setLocalStateRegion(regionFilter); // Update the previous region filter
    }
  }, [regionFilter]);  
  
  const isValid = props.currentPeriodForecastAccuracyData !== undefined; 

  return (
    <div className='carousel-container'>
      {props.currentPeriodForecastAccuracyData.loading || !isValid && (<div className="app-spinner">
        <span className="spinner" />
      </div>)}

      {!props.currentPeriodForecastAccuracyData.loading && isValid && (  
        <div>
          <div className='ytd-forecast-wrapper'>
            {props.currentPeriodForecastAccuracyData && props.currentPeriodForecastAccuracyData.map(f => 
              <ForecastTile key={f.title}
                title={f.title}
                value={f.value?.toFixed(2)} 
                positive={f.positive}
                differenceMetric={f.differenceValue?.toString()} 
              />,
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.forecastAccuracy.currentPeriodLoading,
    currentPeriodForecastAccuracyData: state.forecastAccuracy.currentPeriodForecastAccuracyData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrentPeriodAccuracyData: (periodType, fiscalMonth, quarter, yearFilter, forecastWeek, regionIds, demandClassFilter, categories, calculationType) => dispatch(forecastAccuracyActions.getCurrentPeriodAccuracyData(periodType, fiscalMonth, quarter, yearFilter, forecastWeek, regionIds, demandClassFilter, categories, calculationType)),
  };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(CurrentPeriodForecastAccuracy);