/* eslint-disable react/jsx-key */
import React from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ForecastTile = (props) => {
  return (
    <div className='ytd-forecast-wrapper'>
      <div className='forecast-tile'>
        <h3>{props.title}</h3>
        <span className='trend'>
          {props.positive ? (
            <ExpandLessIcon className="trend-arrow"
              style={{ color: 'green' }} />
          ) : <ExpandMoreIcon className="trend-arrow"
            color='error' /> }
        </span>
        <span className='percentage'>{props.bias != null ? props.bias ? '+' : '-' : ''}{props.value}%</span>
        <span className='difference-metric'
          style={{ color: props.positive ? 'green' : 'red' }}>{props.positive ? '+' : ''}{props.differenceMetric}%</span>
      </div>
    </div>
  );
};
 
export default ForecastTile;