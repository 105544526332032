import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import forecastAccuracyActions from '../../../actions/forecastAccuracyActions';
import { connect } from 'react-redux';
import ForecastTile from '../Common/forecastTile';
import 'react-datepicker/dist/react-datepicker.css';

const ForecastBiasCurrent = (props) => {
  const { productCategoryFilter, demandClassFilter, calculationType, regionFilter, periodType, fiscalMonth, quarter, yearFilter, forecastWeekDate } = props;
  const [localStateRegion, setLocalStateRegion] = useState([]);

  useEffect(() => {
    if (!props.loading){
      props.getForecastBiasCurrentData(periodType, fiscalMonth, quarter, yearFilter, new Date(forecastWeekDate.getTime() - forecastWeekDate.getTimezoneOffset() * 60000).toISOString(), regionFilter, demandClassFilter, productCategoryFilter, calculationType);
    }
  }, [periodType, fiscalMonth, quarter, yearFilter, forecastWeekDate, demandClassFilter, productCategoryFilter, calculationType]);

  useEffect(() => {
    // Call the method only if regionFilter has changed
    if (JSON.stringify(regionFilter) !== JSON.stringify(localStateRegion)){
      props.getForecastBiasCurrentData(periodType, fiscalMonth, quarter, yearFilter, new Date(forecastWeekDate.getTime() - forecastWeekDate.getTimezoneOffset() * 60000).toISOString(), regionFilter, null, null, calculationType);
      setLocalStateRegion(regionFilter); // Update the previous region filter
    }
  }, [regionFilter]);  
  
  const isValid = props.forecastBiasCurrentData !== undefined; 

  return (
    <div className='carousel-container'>
      {!isValid && (<div className="app-spinner">
        <span className="spinner" />
      </div>)}    
      <div>
        <div className='ytd-forecast-wrapper'>
          {props.forecastBiasCurrentData && props.forecastBiasCurrentData.map(f => 
            <ForecastTile 
              key={f.title}
              title={f.title}
              value={f.value?.toFixed(2)} 
              positive={f.positive}
              bias={f.bias}
              differenceMetric={f.differenceValue?.toString()} 
            />,
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.forecastAccuracy.biasCurrentLoading,
    forecastBiasCurrentData: state.forecastAccuracy.forecastBiasCurrentData,
  };
};
 
const mapDispatchToProps = (dispatch) => {
  return {
    getForecastBiasCurrentData: (periodType, fiscalMonth, quarter, yearFilter, forecastWeek, regionIds, demandClasses, categories, calculationType) => dispatch(forecastAccuracyActions.getForecastBiasCurrentData(periodType, fiscalMonth, quarter, yearFilter, forecastWeek, regionIds, demandClasses, categories, calculationType)),
  };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(ForecastBiasCurrent);