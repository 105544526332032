import { PeriodTypeEnum } from "./periodTypeEnum";

  export const regionOptions =  [{
    value: 1,
    label: 'EMEA',
  },
  {
    value: 2,
    label: 'APAC',
  },
  {
    value: 3,
    label: 'LAC',
  }];

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const previousYear = currentYear - 1;

  export const periodTypeOptions =  [{
    Id: PeriodTypeEnum.WEEKCOMMENCING,
    Name: 'Week Commencing',
  },
  {
    Id: PeriodTypeEnum.FISCALMONTH,
    Name: 'Fiscal Month',
  },
  {
    Id: PeriodTypeEnum.QUARTER,
    Name: 'Quarter',
  },
  {
    Id: PeriodTypeEnum.YEARTODATE,
    Name: 'Year to Date',
  }];

 export const yearOptions = [
  { Id: currentYear, Name: currentYear.toString() },
  { Id: previousYear, Name: previousYear.toString() }
];

 export const quarterOptions = [
  { Id: 1, Name: 'Q1' },
{ Id: 2, Name: 'Q2' },
{ Id: 3, Name: 'Q3' },
{ Id: 4, Name: 'Q4' }
];

 export const fiscalMonthOptions = [
  { Id: 1, Name: 'Jan' },
{ Id: 2, Name: 'Feb' },
{ Id: 3, Name: 'Mar' },
{ Id: 4, Name: 'Apr' },
{ Id: 5, Name: 'May' },
{ Id: 6, Name: 'Jun' },
{ Id: 7, Name: 'Jul' },
{ Id: 8, Name: 'Aug' },
{ Id: 9, Name: 'Sep' },
{ Id: 10, Name: 'Oct' },
{ Id: 11, Name: 'Nov' },
{ Id: 12, Name: 'Dec' }
];

  export const productOptions = (productCategoriesData: any) => {
    return productCategoriesData?.map((prod: any) => ({
        value: prod.name,
        label: prod.name,
    }));
  }
    
  export const marketOptions = (mbuData: any) => {
    return mbuData?.map((d: any) => ({
        value: d.name,
        label: d.name,
    }));
  }

    export const demandClassOptions = (dcData: any) => {
    return dcData?.map((d: any) => ({
        value: d.name,
        label: d.name,
    }));
  }

  export const calculationData = [
    {
        Id: 1,
        Name: 'WMAPE',
    },
    {
        Id: 2,
        Name: 'MAPE',
    },
   ];

   export const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      border: '2px solid white',
      alignContent: 'center',
      borderRadius: '15px',
      color: '#6f6f83',
      boxShadow: 'none',
      '&:hover': {
        border: 'none',
      },
      fontSize: '15px',
      fontWeight: 'bold',
      fontFamily: '"Roboto", sans-serif',
    }),
    option: (provided: any, state: { isSelected: any; }) => ({
      ...provided,
      fontWeight: 'normal',
      fontFamily: '"Roboto", sans-serif',
      backgroundColor: state.isSelected ? '#007BFF' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: '#007BFF',
        color: 'white',
      },
    }),
    multiValue: (provided: any, state: any) => ({
      ...provided,
      height: '30px',
      alignItems: 'center',
    }),
  };

  export const isMonday = (date: string | number | Date) => {
    const day = new Date(date).getDay();
    return day == 1;
  };