import React, { useEffect, useState } from 'react';
import forecastAccuracyActions from '../../../actions/forecastAccuracyActions';
import { connect } from 'react-redux';

const LeaderboardOpportunityAreas = (props) => {
  const { demandClassFilter, regionFilter, yearFilter, calculationType } = props;
  const [localStateRegion, setLocalStateRegion] = useState([]);

  useEffect(() => {
    props.getLeaderboardOpportunityAreas(yearFilter, regionFilter, demandClassFilter, calculationType);
  }, [yearFilter, demandClassFilter, calculationType]);
  
  useEffect(() => {
    // Call the method only if regionFilter has changed
    if (JSON.stringify(regionFilter) !== JSON.stringify(localStateRegion)){
      props.getLeaderboardOpportunityAreas(regionFilter, null, null);
      setLocalStateRegion(regionFilter); // Update the previous region filter
    }
  }, [regionFilter]);  

  return (
    <>
      <>  
        <div className="table-body">
          <div style={{ display: 'flex', height: '50px' }}>
          </div>
          <table className={'table-display-mode-5'}>
            <thead>
              <tr>
                <th>Rank</th>
                <th>SKU</th>
                <th>Demand Class</th>
                <th>Region</th>
                <th>Performance (Order Value)</th>
                <th>YTD Impact (Order Value)</th>
              </tr>
            </thead>
            {props.loading ? (
              <div className="app-spinner">
                <span className="spinner" />
              </div>
            ) : (
              <tbody>
                {props.opportunityAreasData && props.opportunityAreasData.entries && props.opportunityAreasData.entries.length > 0  ? (
                  <>
                    {props.opportunityAreasData.entries.map(e => 
                      <tr key={e.rank}>
                        <td><span className={`leaderboard-rank opportunity-rank${e.rank}`}>{e.rank}</span></td>
                        <td>{e.sku}</td>
                        <td>{e.demandClassName}</td>
                        <td>{e.region}</td>
                        <td>{e.forecastAccuracy}%</td>
                        <td>{e.ytdImpact}%</td>
                      </tr>,
                    )}
                  </>
                ) : (
                  <tr><td colSpan={7}
                    style={{ padding: '10px', textAlign: 'center' }}>No data.</td></tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    opportunityAreasData: state.forecastAccuracy.opportunityAreasData,
    loading: state.forecastAccuracy.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLeaderboardOpportunityAreas: (yearFilter, regionIds, demandClasses, calculationTypeId) => dispatch(forecastAccuracyActions.getLeaderboardOpportunityAreas(yearFilter, regionIds, demandClasses, calculationTypeId)),
  };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardOpportunityAreas);