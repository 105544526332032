/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import forecastAccuracyActions from '../../actions/forecastAccuracyActions';
import { connect } from 'react-redux';
import HelpIcon from '@material-ui/icons/Help';
import { Button, Tooltip } from '@material-ui/core';
import { SelectedPeriodForecastAccuracyFilter } from './Common/selectedPeriodForecastAccuracyFilters';
import ForecastQuantityChange from './ForecastQuantityChange/forecastQuantityChange';
import { PeriodTypeEnum } from './Common/periodTypeEnum';
import moment from 'moment';
import LeaderboardMostAccurateMarket from './LeaderboardMostAccurateMarket/leaderboardMostAccurateMarket';
import MarketForecastAccuracy from './MarketForecastAccuracy/marketForecastAccuracy';
import ForecastOrderImpactChart from './ForecastOrderImpactChart/forecastOrderImpactChart';
import YtdForecastAccuracy from './YtdForecastAccuracy/ytdForecastAccuracy';
import CurrentPeriodForecastAccuracy from './CurrentPeriodForecastAccuracy/currentPeriodForecastAccuracy';
import ForecastBiasYtd from './ForecastBiasYtd/forecastBiasYtd';
import ForecastBiasCurrent from './ForecastBiasCurrent/forecastBiasCurrent';
import LeaderboardOpportunityAreas from './LeaderboardOpportunityAreas/leaderboardOpportunityAreas';
import { doDataExport } from '../forecastDashboards/DemandAndSupply/CarouselCharts/CommonCarouselFunctions';

const ForecastAccuracy = (props) => {
  const rankAccess = props.userModules?.includes('Forecast Accuracy - Rank View');
  const dashboardAccess = props.userModules?.includes('Forecast Accuracy - Dashboard View');

  const [loading, setLoading] = useState(true);
  const [productCategoryFilter, setProductCategoryFilter] = useState([]);
  const [demandClassFilter, setDemandClassFilter] = useState([]);
  const [regionFilter, setRegionFilter] = useState([]);
  const [calculationType, setCalculationType] = useState(1);
  const [forecastWeekDate, setForecastWeekDate] = useState(new Date(moment().startOf('isoweek').utc()));
  const [yearFilter, setYearFilter] = useState(new Date().getFullYear().toString());
  const [quarter, setQuarter] = useState(1);
  const [fiscalMonth, setFiscalMonth] = useState(1);
  const [periodType, setPeriodType] = useState(PeriodTypeEnum.WEEKCOMMENCING);
  const isEMEA = props.tenantKey === 'EMEA';
  const [view, setView] = React.useState(rankAccess ? 'rank' : 'dashboard');
  const [demandClassQuery, setDemandClassQuery] = useState('');

  const rankView = view == 'rank';
  const dashboardView = view == 'dashboard';
  
  useEffect(() => {
    async function fetchData() {
      setDemandClassFilter([]);
      setProductCategoryFilter([]);
      await props.getDemandClasses(regionFilter);
      await props.getProductCategories(regionFilter);
      setLoading(false);
    }
   
    fetchData();
  }, [regionFilter]);

  const viewEntry = (marketName) => {
    const newEntry = {
      value: marketName,
      label: marketName,
    };
    setDemandClassFilter([newEntry]);
    setView('dashboard');
  };

  if (loading) {
    return <div className="app-spinner">
      <span className="spinner" />
    </div>;
  }

  const handleExportSummary = () => {
    props.exportRawData(periodType, fiscalMonth, quarter, yearFilter,
      new Date(forecastWeekDate.getTime() - forecastWeekDate.getTimezoneOffset() * 60000).toISOString(),
      regionFilter ? regionFilter.map(x => x.value) : null,
      demandClassFilter ? demandClassFilter.map(x => x.value) : null,
      productCategoryFilter ? productCategoryFilter.map(x => x.value) : null,
    );
  };

  const exportPdf = () => {
    doDataExport('Forecast Accuracy', 'forecastAccuracyWrapper', null, []);
  };

  return  (!loading && 
  <div className="forecast-page page">
    <div className={'floating-panel loaded'}>
      <div className="floating-header">
        <div className="header-title">
          <div
            className={'icon icon-table'}></div>
          <div>
            <h2>Forecast Performance Dashboard</h2>
            <p>View forecast performance metrics for demand classes</p>
          </div>
        </div>  
        <div style={{ textAlign: 'end' }}>
          {rankAccess &&
          <Button onClick={() => setView('rank')}
            variant={rankView ? 'contained' : 'outlined'}> <div
              className="icon icon-rank"
              alt="orderly logo"
            />Rank View</Button>
          }
          {dashboardAccess &&
          <Button onClick={() => setView('dashboard')}
            variant={dashboardView ? 'contained' : 'outlined'}> <div
              className="icon icon-dashboard"
              alt="orderly logo"
            />Dashboard</Button>
          }
          {dashboardView && dashboardAccess &&
          <div style={{ display: 'flex' }}><div className="group-details accordion download-container summary"
            style={{ backgroundColor: 'inherit', width: 'fit-content', marginLeft: 'auto', marginTop: 0 }}>
            <div className="accordion-header"
              style={{ justifyContent: 'center', marginLeft: '10px' }}>
              <h3>Export Data</h3>
            </div>
            <div className="accordion-body horizontal-flex order-fields"
              style={{ justifyContent: 'end' }}>
              <div>
                <button
                  className="action export"
                  type="button"
                  title="Export"
                  onClick={() => handleExportSummary()}
                >
                  <div
                    className="icon icon-download-light"
                    alt="Export" />
                </button>
              </div>
            </div>
          </div><div className="group-details accordion download-container summary"
            style={{ backgroundColor: 'inherit', width: 'fit-content', marginLeft: 'auto', marginTop: 0 }}>
            <div className="accordion-header"
              style={{ justifyContent: 'center', marginLeft: '10px' }}>
              <h3>Export PDF</h3>
            </div>
            <div className="accordion-body horizontal-flex order-fields"
              style={{ justifyContent: 'end' }}>
              <div>
                <button
                  className="action export"
                  type="button"
                  title="Export"
                  onClick={() => exportPdf() }
                >
                  <div
                    className="icon icon-download-light"
                    alt="Export" />
                </button>
              </div>
            </div>
          </div></div>
          }
        </div>
      </div>
      {props.exportLoading &&
          <div className="app-spinner">
            <span className="spinner" />
          </div>
      }
      <div className="menu-container"
        id='forecastAccuracyWrapper'>
        <SelectedPeriodForecastAccuracyFilter
          handleProductFilterChange={(value) => setProductCategoryFilter(value)}
          regionFilter={regionFilter}
          handleRegionFilterChange={(value) => setRegionFilter(value)}
          demandClassFilter={demandClassFilter}
          handleDemandClassFilterChange={(value) => setDemandClassFilter(value)}
          productCategoryFilter={productCategoryFilter}
          setCalculationType={(value) => setCalculationType(value)}
          calculationType={calculationType}
          demandClassData={props.demandClassData}
          productCategoriesData={props.productCategoriesData}
          forecastWeek={forecastWeekDate}
          setForecastWeek={(value) => setForecastWeekDate(value)}
          setYearFilter={(value) => setYearFilter(value)}
          setQuarter={(value) => setQuarter(value)}
          setFiscalMonth={(value) => setFiscalMonth(value)}
          setPeriodType={(value) => setPeriodType(value)}
          yearFilter={yearFilter}
          fiscalMonth={fiscalMonth}
          quarter={quarter}
          periodType={periodType}
          view={view}
        />
        {rankView && rankAccess &&
        <div className='leaderboard-wrapper'>
          <div className="dashboard-container-forecast-accuracy table-container leaderboard-box">
            <div className="table-header"
              style={{ justifyContent: 'center' }}>
              <h2> 
              Most Accurate Demand Classes
              </h2>
              <div></div>
            </div>
            <LeaderboardMostAccurateMarket
              regionFilter={regionFilter}
              yearFilter={yearFilter}
              forecastWeekDate={forecastWeekDate}
              fiscalMonth={fiscalMonth}
              quarter={quarter}
              periodType={periodType}
              demandClassFilter={demandClassFilter}
              demandClassQuery={demandClassQuery}
              setDemandClassQuery={(val) => setDemandClassQuery(val)}
              viewEntry={(val) => viewEntry(val)}/> 
          </div> 
          <div className="dashboard-container-forecast-accuracy table-container leaderboard-box">
            <div className="table-header"
              style={{ justifyContent: 'center' }}>
              <h2> 
              Improvement Opportunities
              </h2>
              <div></div>
            </div>
            <LeaderboardOpportunityAreas
              regionFilter={regionFilter}
              yearFilter={yearFilter}
              demandClassFilter={demandClassFilter}
              calculationType={calculationType}/> 
          </div> 
        </div>
        }
        {isEMEA && dashboardView &&
        <div>     
          <div className="dashboard-container-forecast-accuracy">
            <h3 className='dashboard-title'>Demand Class Forecast Accuracy</h3>
            <MarketForecastAccuracy
              regionFilter={regionFilter}
              yearFilter={yearFilter}
              forecastWeekDate={forecastWeekDate}
              fiscalMonth={fiscalMonth}
              quarter={quarter}
              periodType={periodType}
              demandClassFilter={demandClassFilter}
              calculationType={calculationType}
              productCategoryFilter={productCategoryFilter}/> 
          </div> 
          <div className="dashboard-container-forecast-accuracy">
            <h3 className='dashboard-title'>Forecast Quantity Change</h3>
            <ForecastQuantityChange
              yearFilter={yearFilter}
              forecastWeekDate={forecastWeekDate}
              fiscalMonth={fiscalMonth}
              quarter={quarter}
              periodType={periodType}
              regionFilter={regionFilter}
              demandClassFilter={demandClassFilter}
              calculationType={calculationType}
              productCategoryFilter={productCategoryFilter}/> 
          </div> 
          <div className="dashboard-container-forecast-accuracy"> 
            <h3 className='dashboard-title'> Forecast Order Impact
            </h3>
            <br/>
            <ForecastOrderImpactChart
              yearFilter={yearFilter}
              forecastWeekDate={forecastWeekDate}
              fiscalMonth={fiscalMonth}
              quarter={quarter}
              periodType={periodType}
              regionFilter={regionFilter}
              demandClassFilter={demandClassFilter}
              calculationType={calculationType}
              productCategoryFilter={productCategoryFilter} />
          </div>

          <div className="dashboard-container-forecast-accuracy"> 
            <h3 className='dashboard-title'> Forecast Accuracy YTD 
              <Tooltip 
                title="The average forecast accuracy for all selected stores and 
              product categories from the star of the year to the current forecast period.
              Resets to 0% on the new year."
                aria-label="add">
                <HelpIcon className="ytd-tooltip" />
              </Tooltip>
            </h3>
            <br/>
            <YtdForecastAccuracy
              regionFilter={regionFilter}
              demandClassFilter={demandClassFilter}
              calculationType={calculationType}
              productCategoryFilter={productCategoryFilter} />
          </div>
    
          <div className="dashboard-container-forecast-accuracy"> 
            <h3 className='dashboard-title'> Current Forecast Accuracy
              <Tooltip 
                title="The average forecast accuracy for all selected stores and product categories for the current Forecast period."
                aria-label="add">
                <HelpIcon className="ytd-tooltip" />
              </Tooltip>
            </h3>
            <br/>
            <CurrentPeriodForecastAccuracy
              yearFilter={yearFilter}
              forecastWeekDate={forecastWeekDate}
              fiscalMonth={fiscalMonth}
              quarter={quarter}
              periodType={periodType}
              regionFilter={regionFilter}
              demandClassFilter={demandClassFilter}
              calculationType={calculationType}
              productCategoryFilter={productCategoryFilter} />
          </div>
        
          <div className="dashboard-container-forecast-accuracy"> 
            <h3 className='dashboard-title'> Forecast Bias YTD 
              <Tooltip 
                title="The variance factor between the final forecast value and the first forecast submission for the selected stores on 
            average across each of this years forecast periods."
                aria-label="add">
                <HelpIcon className="ytd-tooltip" />
              </Tooltip>
            </h3>
            <br/>
            <ForecastBiasYtd
              regionFilter={regionFilter}
              demandClassFilter={demandClassFilter}
              calculationType={calculationType}
              productCategoryFilter={productCategoryFilter} />
          </div>

          <div className="dashboard-container-forecast-accuracy"> 
            <h3 className='dashboard-title'> Current Forecast Bias 
              <Tooltip 
                title="The variance factor between the final forecast value and the first forecast submission for the selected stores in the current forecast period."
                aria-label="add">
                <HelpIcon className="ytd-tooltip" />
              </Tooltip>
            </h3>
            <br/>
            <ForecastBiasCurrent 
              yearFilter={yearFilter}
              forecastWeekDate={forecastWeekDate}
              fiscalMonth={fiscalMonth}
              quarter={quarter}
              periodType={periodType}
              regionFilter={regionFilter}
              demandClassFilter={demandClassFilter}
              calculationType={calculationType}
              productCategoryFilter={productCategoryFilter}/>
          </div>
        </div>
        }
      </div> 
    </div>
  </div>);  
};

const mapStateToProps = (state) => {
  return {
    userModules: state.auth.modules,
    exportLoading: state.forecastAccuracy.exportLoading,
    demandClassData: state.forecastAccuracy.demandClassData,
    productCategoriesData: state.forecastAccuracy.productCategoriesData,
    tenantKey: state.auth.tenant,
  };
};
 

const mapDispatchToProps = (dispatch) => {
  return {
    exportRawData: (periodType, fiscalMonth, quarter, yearFilter, forecastWeek, regionIds, marketIds, productCategories) => dispatch(forecastAccuracyActions.exportRawData(periodType, fiscalMonth, quarter, yearFilter, forecastWeek, regionIds, marketIds, productCategories)),
    getDemandClasses: (regionIds) => dispatch(forecastAccuracyActions.getDemandClasses(regionIds)),
    getProductCategories: (regionIds) => dispatch(forecastAccuracyActions.getProductCategories(regionIds)),
  };
};
 

export default connect(mapStateToProps, mapDispatchToProps)(ForecastAccuracy);
