import React from 'react';
import { Route, Switch } from 'react-router';
// Platform
import SideMenu from './components/platform/sideMenu/sideMenu';
import LoginPage from './components/platform/login/loginPage';
import Login from './components/platform/login/login';
import ForgottenPassword from './components/platform/login/forgottenPassword';
import ResetPassword from './components/platform/login/resetPassword';
import FirstResetPassword from './components/platform/login/firstResetPassword';
import Header from './components/platform/header/header';
import Footer from './components/platform/footer/footer';
import Home from './components/platform/home/home';
import TermsAndConditions from './components/platform/termsAndConditions/termsAndConditions';
import TwoFactor from './components/platform/login/twoFactor';
import EmailDocumentManagement from './components/admin/emailDocumentManagement/emailDocumentManagement';
import EditEmailDocumentManagement from './components/admin/emailDocumentManagement/editEmailDocumentManagement/editEmailDocumentManagement';

// Users and Groups
import EditUser from './components/admin/userManagement/editUser';
import Users from './components/admin/userManagement/users';
import EditModuleGroup from './components/admin/moduleGroupManagement/editModuleGroup';
import ModuleGroups from './components/admin/moduleGroupManagement/moduleGroups';
import StoreGroups from './components/admin/customerGroupManagement/customerGroups';
import EditStoreGroup from './components/admin/customerGroupManagement/editCustomerGroup';
import CustomerUserConifgUpload from './components/admin/userManagement/customerUserConifgUpload';
// Notifications/Messaging Module
import Messages from './components/notifications/Messages/Messages';
import MessageAdmin from './components/notifications/MessageAdmin/MessageAdmin';
import MessageEdit from './components/notifications/MessageEdit/MessageEdit';
import EmailDistributionList from './components/admin/emailDistributionList/EmailDistributionList.jsx';
// CustomerService Module
import PortalFeedback from './components/customer/PortalFeedback/PortalFeedback';
import ProductFeedback from './components/customer/ProductFeedback/ProductFeedback';
import SupplierFeedback from './components/customer/SupplierFeedback/SupplierFeedback';
import ContactDetails from './components/customer/ContactDetails/ContactDetails';
// Documents Module
import MyDocuments from './components/documents/MyDocuments/MyDocuments';
import DocumentManagement from './components/documents/DocumentManagement/DocumentManagement';
// Orders Module
import OverdueInvoices from './components/orders/OverdueInvoices/OverdueInvoices';
import RslPerformance from './components/orders/RslPerformance/RslPerformance';
import BookingForms from './components/orders/BookingForm/BookingForm';
// Item Catalogue Module
import ItemCatalogue from './components/items/ItemCatalogue/ItemCatalogue';
import ItemDetail from './components/items/ItemDetail/ItemDetail';
// Pricing Module
import VasPriceList from './components/pricing/VasPriceList/VasPriceList';
import ItemPriceList from './components/pricing/ItemPricing/ItemPricing';
// Master File
import MasterFileUpload from './components/masterFile/masterFileUpload/MasterFileUpload';
import CustomerRequirementsForm from './components/masterFile/customerRequirementsForm/CustomerRequirementsForm';
// Policies
import TermsAndConditionsPolicy from './components/policies/termsAndConditions/termsAndConditionsPolicy';
// forecast
import ForecastChangeRequest from './components/forecast/forecastChangeRequest/ForecastChangeRequest';
import ForecastChangeRequestSummaryUpload from './components/forecast/ForecastChangeRequestUpload/ForecastChangeRequestUpload';
// marketing
import WhatIsMyStarbucks from './components/marketing/whatIsMyStarbucks';

// ordertracker
import CreateOrder from './components/ordertracker/CreateOrder/CreateOrder';
import SearchOrders from './components/ordertracker/SearchOrders/SearchOrders';
import BulkUpdate from './components/ordertracker/BulkUpdate/BulkUpdate';
import EditOrder from './components/ordertracker/EditOrder/EditOrder';
import CustomerInProgress from './components/ordertracker/CustomerInProgress/CustomerInProgress';


//apac order tracker
import InProgressSummary from './components/ordertracker/InProgressSummary/InProgressSummary.jsx';
import AotOrders from './components/ordertracker/AotOrders/AotOrders';
import ShippingDetails from './components/ordertracker/ShippingDetails/ShippingDetails';
import DocumentArchive from './components/ordertracker/DocumentArchive/DocumentArchive';
import OrderDocuments from './components/ordertracker/OrderDocuments/OrderDocuments';

// Connect
import Connect from './components/connect/mainView/connect';
import ConnectConfiguration from './components/connect/configuration/configuration';
import contentBuilder from './components/connect/contentBuilder/contentBuilder';
import connectDigitalAssetManagement from './components/connect/digitalAssetManagement/DigitalAssetManagement';
import connectReporting from './components/connect/connectDashboard/connectReporting';

import InFullDashboard from './components/kpi/InFull/InFullDashboard';
import InFullDashboardAdmin from './components/kpi/InFull/InFullDashboardAdmin';

import KpiConfigurations from './components/admin/kpiConfigurations/kpiConfigurations';

import './style/styles.scss';

import { connect } from 'react-redux';
import MyAccount from './components/platform/myAccount/MyAccount';
import platformUsage from './components/reporting/platformUsage/platformUsage';
import whatIsOrderly from './components/marketing/whatIsOrderly';
import InstantMessaging from './components/instantMessaging/InstantMessaging';
import InstantMessagingAdmin from './components/instantMessaging/InstantMessagingAdmin';
import InstantMessagingArchive from './components/instantMessaging/InstantMessagingArchive';
import InstantMessagingArchiveDetail from './components/instantMessaging/InstantMessagingArchiveDetail';

import SiteIDApplications from './components/siteID/siteIDApplications/SiteIDApplications';
import SiteIDEquityStores from './components/siteID/siteIDEquityStores/SiteIDEquityStores';
import editCompanies from './components/siteID/siteIDCompanyManagement/editCompanies';
import companies from './components/siteID/siteIDCompanyManagement/companies';
import SiteIDDtmpFileUpload from './components/siteID/siteIDDtmpFileUpload/SiteIDDtmpFileUpload';
import activateAccount from './components/platform/login/activateAccount';
import SiteIDReporting from './components/siteID/siteIDReporting/SiteIDReporting';
import whatIsSiteId from './components/marketing/whatIsSiteId';
import Dashboard from './components/ordertracker/Dashboard/Dashboard';
import contentList from './components/connect/contentList/contentList';
import contentLabels from './components/connect/contentLabels/contentLabels';
import editLabel from './components/connect/contentLabels/editLabel';
import TaskAdmin from './components/connect/tasks/TaskAdmin/TaskAdmin';
import TaskEdit from './components/connect/tasks/TaskEdit/TaskEdit';
import Tasks from './components/connect/tasks/Tasks/Tasks';
import defaultResponses from './components/siteID/SiteIDDefaultResponses/defaultResponses';
import editDefaultResponse from './components/siteID/SiteIDDefaultResponses/editDefaultResponse';
import SiteIDApplicationUpload from './components/siteID/siteIDApplicationUpload/SiteIDApplicationUpload';

//LSPOS
import PendingCategoryReview from './components/lsPos/pendingCategoryReview/PendingCategoryReview';
import PendingMdioList from './components/lsPos/pendingMdio/PendingMdioList';
import EmailConfiguration from './components/lsPos/emailConfiguration/EmailConfiguration';
import PendingMdioDetail from './components/lsPos/pendingMdio/PendingMdioDetail';
import RequestItemNumberForm from './components/lsPos/posItemNumberRequest/RequestItemNumber';
import PosItemNumberRequests from './components/lsPos/posItemNumberRequest/PosItemNumberRequests';
import Chatbot from './components/customer/Chatbot/Chatbot';

import ShipmentLogUpload from './components/ordertracker/ShipmentLogUpload/ShipmentLogUpload';

//LAC
import CustomerRequirementsOds from './components/masterFile/customerRequirementsOds/CustomerRequirementsOds';
import CsrDashboard from './components/csrDashboard/csrDashboard';

import FcrManagement from './components/fcr/FcrManagement';
import FcrDetail from './components/fcr/FcrDetail';

//Booking Request Form
import BrfOrders from './components/bookingRequestForm/BrfOrders/BrfOrders';
import BookingRequestForm from './components/bookingRequestForm/BookingRequestForm/BookingRequestForm';
import NotifyPartyDetails from './components/bookingRequestForm/NotifyPartyDetails/NotifyPartyDetails';
import RateCardUpload from './components/bookingRequestForm/RateCardUpload/RateCardUpload';

//EMEA FORECAST DASHBOARDS
import ForecastAccuracy from './components/forecastAccuracy/forecastAccuracy.jsx';
//APAC FORECAST DASHBOARD
import ForecastDashboard from './components/forecastDashboards/forecastDashboard.jsx';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    const { auth } = this.props;
    return (
      <div className="app">
        {!auth.isAuthenticated && (
          <Switch>
            <Route
              exact
              path="/whatIsMyStarbucks"
              component={WhatIsMyStarbucks}
            />
            <Route exact
              path="/whatisorderly"
              component={whatIsOrderly} />
            <Route exact
              path="/whatissiteid"
              component={whatIsSiteId} />
            <Route path="/">
              <LoginPage>
                <Switch>
                  <Route
                    exact
                    path="/forgottenPassword"
                    component={ForgottenPassword}
                  />
                  <Route
                    exact
                    path="/resetPassword/:token"
                    component={ResetPassword}
                  />
                  <Route
                    exact
                    path="/activateaccount/:token"
                    component={activateAccount}
                  />
                  <Route path="/"
                    component={Login} />
                </Switch>
              </LoginPage>
            </Route>
          </Switch>
        )}
        {auth.isAuthenticated && auth.resetPassword && (
          <LoginPage>
            <FirstResetPassword />
          </LoginPage>
        )}
        {auth.isAuthenticated && auth.preAuth && (
          <LoginPage>
            <TwoFactor />
          </LoginPage>
        )}
        {auth.isAuthenticated && !auth.resetPassword && !auth.preAuth && !auth.hasAcceptedClientTerms && (
          <div className="termswrapper">
            <TermsAndConditions />
            <Footer />
          </div>
        )}
        {auth.isAuthenticated && !auth.resetPassword && !auth.preAuth && auth.hasAcceptedClientTerms && (
          <Switch>
            <Route exact
              path="/whatisorderly"
              component={whatIsOrderly} />
            <Route exact
              path="/whatissiteid"
              component={whatIsSiteId} />
            <Route exact
              path="/connect">
              <div className="connectwrapper">
                <Connect />
              </div>
            </Route>
            <Route path="/">
              <div className="app-body">
                <div className="app-left">
                  <SideMenu />
                </div>
                <div id="appRight"
                  className="app-right">
                  <Header />
                  <div className="app-content">
                    <Switch>
                      <Route exact
                        path="/module/:moduleId"
                        component={Home} />
                      <Route
                        exact
                        path="/module/:moduleId/editusers"
                        component={Users}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/editusers/:userId"
                        component={EditUser}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/editmodulegroups"
                        component={ModuleGroups}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/customeruserconfigurationupload"
                        component={CustomerUserConifgUpload}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/EditModuleGroups/:groupId"
                        component={EditModuleGroup}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/editcustomergroups"
                        component={StoreGroups}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/editcustomergroups/:groupId"
                        component={EditStoreGroup}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/messages"
                        component={Messages}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/messageAdmin"
                        component={MessageAdmin}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/platformUsage"
                        component={platformUsage}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/messageAdmin/:notificationId"
                        component={MessageEdit}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/SupplierFeedback"
                        component={SupplierFeedback}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/ProductFeedback"
                        component={ProductFeedback}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/PortalFeedback"
                        component={PortalFeedback}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/mydocuments"
                        component={MyDocuments}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/DocumentManagement"
                        component={DocumentManagement}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/EmailDocumentManagement"
                        component={EmailDocumentManagement}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/EmailDocumentManagement/:id"
                        component={EditEmailDocumentManagement}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/OverdueInvoices"
                        component={OverdueInvoices}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/RSLPerformance"
                        component={RslPerformance}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/iteminformation"
                        component={ItemCatalogue}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/hazardous"
                        component={ItemCatalogue}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/POSItemCatalogue"
                        component={ItemCatalogue}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/POSItemCatalogue/:posItemId"
                        component={ItemDetail}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/ItemNumberRequests/:itemRequestId"
                        component={RequestItemNumberForm}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/ItemNumberRequests"
                        component={PosItemNumberRequests}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/iteminformation/:storeId"
                        component={ItemDetail}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/hazardous/:storeId"
                        component={ItemDetail}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/OnLineBookingForm"
                        component={BookingForms}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/vaspricelist"
                        component={VasPriceList}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/UploadMasterFile"
                        component={MasterFileUpload}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/customerrequirements-lac"
                        component={CustomerRequirementsOds}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/customerrequirements"
                        component={CustomerRequirementsForm}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/itempricelist"
                        component={ItemPriceList}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/forecastchangerequest"
                        component={ForecastChangeRequest}
                      />

                      <Route
                        exact
                        path="/module/:moduleId/PendingCategoryReview"
                        component={PendingCategoryReview}
                      />

                      <Route
                        exact
                        path="/module/:moduleId/PendingCategoryReview/:itemRequestId"
                        component={RequestItemNumberForm}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/PendingMDIOItemCreation"
                        component={PendingMdioList}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/PendingMDIOItemCreation/:posItemId"
                        component={PendingMdioDetail}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/EmailNotifications"
                        component={EmailConfiguration}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/emailDistributionList"
                        component={EmailDistributionList}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/TermsAndConditions"
                        component={TermsAndConditionsPolicy}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/contactdetails"
                        component={ContactDetails}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/chatbot"
                        component={Chatbot}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/instantMessaging"
                        component={InstantMessaging}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/InstantMessagingAdmin"
                        component={InstantMessagingAdmin}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/InstantMessagingArchive"
                        component={InstantMessagingArchive}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/InstantMessagingArchive/:conversationId"
                        component={InstantMessagingArchiveDetail}
                      />
                      <Route
                        exact
                        path="/contactdetails"
                        component={ContactDetails}
                      />
                      <Route exact
                        path="/myaccount"
                        component={MyAccount} />
                      <Route
                        exact
                        path="/TermsAndConditions"
                        component={TermsAndConditionsPolicy}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/SiteIDApplications"
                        component={SiteIDApplications}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/SiteIDApplications/:applicationId"
                        component={SiteIDApplications}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/SiteIDEquityStores"
                        component={SiteIDEquityStores}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/editcompanies"
                        component={companies}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/editcompanies/:companyId"
                        component={editCompanies}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/SiteIDProcessReporting"
                        component={SiteIDReporting}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/SiteIDDtmpFileUpload"
                        component={SiteIDDtmpFileUpload}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/SiteIDApplicationUpload"
                        component={SiteIDApplicationUpload}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/OT-AddNew"
                        component={CreateOrder}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/OT-InProgress"
                        render={(props) => <SearchOrders {...props}
                          completed={false} />}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/OT-Completed"
                        render={(props) => <SearchOrders {...props}
                          completed />}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/OT-ViewOrder"
                        component={EditOrder}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/OT-ViewCustomerOrder"
                        render={(props) => <EditOrder {...props}
                          readOnly />}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/OT-ViewInProgress"
                        render={(props) => <CustomerInProgress {...props}
                          completed={false} />}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/OT-ViewCompleted"
                        render={(props) => <CustomerInProgress {...props}
                          completed />}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/OT-BulkChange"
                        component={BulkUpdate}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/OT-Dashboards-InProgress"
                        render={(props) => <Dashboard archive={false}
                          {...props} />}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/OT-Dashboards-Archive"
                        render={(props) => <Dashboard archive
                          {...props} />}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/AOT-Admin-InProgressSummary"
                        component={InProgressSummary}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/AOT-ClosedOrders"
                        component={AotOrders}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/AOT-InProgress"
                        component={AotOrders}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/FCR-Management"
                        component={FcrManagement}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/FCR-Management/:id"
                        component={FcrDetail}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/AOT-Admin-ClosedOrders"
                        component={AotOrders}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/AOT-Admin-InProgress"
                        component={AotOrders}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/BRF-Edit/:id"
                        component={BookingRequestForm}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/BRF-NotifyPartyDetailsUpload"
                        component={NotifyPartyDetails}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/BRF-Create"
                        component={BookingRequestForm}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/BRF-InProgress"
                        component={BrfOrders}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/BRF-Closed"
                        component={BrfOrders}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/RateCardUpload"
                        component={RateCardUpload}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/ForecastChangeRequestSummaryUpload"
                        component={ForecastChangeRequestSummaryUpload}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/DocumentArchive"
                        component={DocumentArchive}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/AOT-ShippingDetails/:trackingNumber"
                        component={ShippingDetails}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/AOT-OrderDocuments/:trackingNumber"
                        component={OrderDocuments}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/ShipmentLogUpload"
                        component={ShipmentLogUpload}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/csrshipmentdashboard"
                        component={CsrDashboard}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/InFull-KPI"
                        render={(props) => <InFullDashboard {...props} />}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/InFull-KPI-Admin"
                        render={(props) => <InFullDashboardAdmin {...props} />}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/KPI-Configuration"
                        render={(props) => <KpiConfigurations {...props} />}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/ConnectConfiguration"
                        component={ConnectConfiguration}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/DigitalAssetManagement"
                        component={connectDigitalAssetManagement}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/ContentList/ContentBuilder/:contentId"
                        component={contentBuilder}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/ContentArchive/ContentBuilder/:contentId"
                        component={contentBuilder}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/ContentList"
                        component={contentList}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/ContentArchive"
                        component={contentList}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/ContentLabels"
                        component={contentLabels}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/ContentLabels/:labelId"
                        component={editLabel}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/SiteIDDefaultReponses"
                        component={defaultResponses}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/SiteIDDefaultReponses/:responseId"
                        component={editDefaultResponse}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/TaskManagement"
                        component={TaskAdmin}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/TaskManagement/:taskId"
                        component={TaskEdit}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/Tasks"
                        component={Tasks}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/EngagementDashboard"
                        component={connectReporting}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/ForecastAccuracy"
                        component={ForecastAccuracy}
                      />
                      <Route
                        exact
                        path="/module/:moduleId/ForecastDashboard"
                        component={ForecastDashboard}
                      />
                      <Route path="/"
                        component={Home} />
                    </Switch>
                  </div>
                  <Footer />
                </div>
              </div>
            </Route>
          </Switch>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
)(App);
