
import {
  GETPRODUCTCATEGORIES_SUCCESS, GETPRODUCTCATEGORIES_FAILURE,
  GETDEMANDCLASSES_SUCCESS,
  GETDEMANDCLASSES_FAILURE,
  GETMARKETFORECASTACCURACY_REQUEST,
  GETMARKETFORECASTACCURACY_SUCCESS,
  GETMARKETFORECASTACCURACY_FAILURE,
  GETFORECASTQUANTITYCHANGE_REQUEST,
  GETFORECASTQUANTITYCHANGE_SUCCESS,
  GETFORECASTQUANTITYCHANGE_FAILURE,
  GETFORECASTACCURACYYTD_REQUEST,
  GETFORECASTACCURACYYTD_SUCCESS,
  GETFORECASTACCURACYYTD_FAILURE,
  GETCURRENTPERIODACCURACY_REQUEST,
  GETCURRENTPERIODACCURACY_SUCCESS,
  GETCURRENTPERIODACCURACY_FAILURE,
  GETFORECASTORDERIMPACT_REQUEST,
  GETFORECASTORDERIMPACT_SUCCESS,
  GETFORECASTORDERIMPACT_FAILURE,
  GETFORECASTBIASYTD_REQUEST,
  GETFORECASTBIASYTD_SUCCESS,
  GETFORECASTBIASYTD_FAILURE,
  GETMOSTACCURATEMARKETSLEADERBOARD_REQUEST,
  GETMOSTACCURATEMARKETSLEADERBOARD_SUCCESS,
  GETMOSTACCURATEMARKETSLEADERBOARD_FAILURE,
  GETOPPORTUNITYAREASLEADERBOARD_REQUEST,
  GETOPPORTUNITYAREASLEADERBOARD_SUCCESS,
  GETOPPORTUNITYAREASLEADERBOARD_FAILURE,
  EXPORTFORECASTACCURACY_REQUEST,
  EXPORTFORECASTACCURACY_SUCCESS,
  EXPORTFORECASTACCURACY_FAILURE,
  GETFORECASTBIASCURRENT_REQUEST,
  GETFORECASTBIASCURRENT_SUCCESS,
  GETFORECASTBIASCURRENT_FAILURE } from './actionTypes';

import { FORECAST_ACCURACY_BASE_URL } from './actionEndpointBaseUrl';
  
const forecastAccuracyActions = {

  exportRawData: (periodType, fiscalMonth, quarter, yearFilter, forecastWeek, regionIds, demandClasses, productCategories) => (dispatch) => {
    dispatch({
      type: EXPORTFORECASTACCURACY_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `${FORECAST_ACCURACY_BASE_URL}/ExportRawData?periodType=${periodType}&month=${fiscalMonth}&quarter=${quarter}&year=${yearFilter}&forecastWeek=${forecastWeek}${productCategories ? productCategories.map(x => `&categories=${x}` ).join('') : ''}` +
        `${demandClasses ? demandClasses.map(x => `&demandClasses=${x}` ).join('') : ''}${regionIds ? regionIds.map(x => `&regionIds=${x}` ).join('') : ''}`,
        successAction: EXPORTFORECASTACCURACY_SUCCESS,
        failureAction: EXPORTFORECASTACCURACY_FAILURE,
      },
    });
  },

  getMarketForecastAccuracyData: (periodType, fiscalMonth, quarter, yearFilter, forecastWeek, productCategories, calculationType, demandClasses, regionIds) => (dispatch) => {
    dispatch({
      type: GETMARKETFORECASTACCURACY_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `${FORECAST_ACCURACY_BASE_URL}/GetMarketForecastAccuracy?periodType=${periodType}&month=${fiscalMonth}&quarter=${quarter}&year=${yearFilter}&forecastWeek=${forecastWeek}${productCategories ? productCategories.map(x => `&categories=${x}` ).join('') : ''}` +
        `&calculationType=${calculationType}${demandClasses ? demandClasses.map(x => `&demandClasses=${x}` ).join('') : ''}${regionIds ? regionIds.map(x => `&regionIds=${x}` ).join('') : ''}`,
        successAction: GETMARKETFORECASTACCURACY_SUCCESS,
        failureAction: GETMARKETFORECASTACCURACY_FAILURE,
      },
    });
  },

  getForecastQuantityChange: (periodType, fiscalMonth, quarter, yearFilter, forecastWeek, productCategories, demandClasses, regionIds, quantityType) => (dispatch) => {
    dispatch({
      type: GETFORECASTQUANTITYCHANGE_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `${FORECAST_ACCURACY_BASE_URL}/GetForecastQuantityChange?periodType=${periodType}&month=${fiscalMonth}&quarter=${quarter}&year=${yearFilter}&forecastWeek=${forecastWeek}${productCategories ? productCategories.map(x => `&categories=${x}` ).join('') : ''}` +
        `${demandClasses ? demandClasses.map(x => `&demandClasses=${x}` ).join('') : ''}${regionIds ? regionIds.map(x => `&regionIds=${x}` ).join('') : ''}&quantityType=${quantityType}`,
        successAction: GETFORECASTQUANTITYCHANGE_SUCCESS,
        failureAction: GETFORECASTQUANTITYCHANGE_FAILURE,
      },
    });
  },

  getForecastAccuracyYtdData: (regions, demandClasses, categories, calculationType) => (dispatch) => {
    dispatch({
      type: GETFORECASTACCURACYYTD_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `${FORECAST_ACCURACY_BASE_URL}/GetForecastAccuracyYtd?${regions ? regions.map(x => `&regionIds=${x.value}` ).join('') : ''}` +
        `${demandClasses ? demandClasses.map(x => `&demandClasses=${x.value}` ).join('') : ''}${categories ? categories.map(x => `&categoryIds=${x.value}` ).join('') : ''}` +
        `&calculationTypeId=${calculationType}`,
        successAction: GETFORECASTACCURACYYTD_SUCCESS,
        failureAction: GETFORECASTACCURACYYTD_FAILURE,
      },
    });
  },
  
  getCurrentPeriodAccuracyData: (periodType, fiscalMonth, quarter, yearFilter, forecastWeek, regions, demandClasses, categories, calculationType) => (dispatch) => {
    dispatch({
      type: GETCURRENTPERIODACCURACY_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `${FORECAST_ACCURACY_BASE_URL}/GetForecastAccuracyCurrentPeriod?periodType=${periodType}&month=${fiscalMonth}&quarter=${quarter}&year=${yearFilter}&forecastWeek=${forecastWeek}${regions ? regions.map(x => `&regionIds=${x.value}` ).join('') : ''}` +
        `${demandClasses ? demandClasses.map(x => `&demandClasses=${x.value}` ).join('') : ''}${categories ? categories.map(x => `&categories=${x.value}` ).join('') : ''}` +
        `&calculationTypeId=${calculationType}`,
        successAction: GETCURRENTPERIODACCURACY_SUCCESS,
        failureAction: GETCURRENTPERIODACCURACY_FAILURE,
      },
    });
  },

  getForecastOrderImpactData: (periodType, fiscalMonth, quarter, yearFilter, forecastWeek, regions, demandClasses, categories) => (dispatch) => {
    dispatch({
      type: GETFORECASTORDERIMPACT_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `${FORECAST_ACCURACY_BASE_URL}/GetForecastOrderImpact?periodType=${periodType}&month=${fiscalMonth}&quarter=${quarter}&year=${yearFilter}&forecastWeek=${forecastWeek}${regions ? regions.map(x => `&regionIds=${x.value}` ).join('') : ''}` +
        `${demandClasses ? demandClasses.map(x => `&demandClasses=${x.value}` ).join('') : ''}${categories ? categories.map(x => `&categories=${x.value}` ).join('') : ''}`,
        successAction: GETFORECASTORDERIMPACT_SUCCESS,
        failureAction: GETFORECASTORDERIMPACT_FAILURE,
      },
    });
  },

  getForecastBiasYtdData: (regions, demandClasses, categories, calculationType) => (dispatch) => {
    dispatch({
      type: GETFORECASTBIASYTD_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `${FORECAST_ACCURACY_BASE_URL}/GetForecastBiasYtd?${regions ? regions.map(x => `&regionIds=${x.value}` ).join('') : ''}` +
        `${demandClasses ? demandClasses.map(x => `&demandClasses=${x.value}` ).join('') : ''}${categories ? categories.map(x => `&categories=${x.value}` ).join('') : ''}` +
        `&calculationTypeId=${calculationType}`,
        successAction: GETFORECASTBIASYTD_SUCCESS,
        failureAction: GETFORECASTBIASYTD_FAILURE,
      },
    });
  },

  getForecastBiasCurrentData: (periodType, fiscalMonth, quarter, yearFilter, forecastWeek, regions, demandClasses, categories, calculationType) => (dispatch) => {
    dispatch({
      type: GETFORECASTBIASCURRENT_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `${FORECAST_ACCURACY_BASE_URL}/GetForecastBiasCurrent?periodType=${periodType}&month=${fiscalMonth}&quarter=${quarter}&year=${yearFilter}&forecastWeek=${forecastWeek}${regions ? regions.map(x => `&regionIds=${x.value}` ).join('') : ''}` +
        `${demandClasses ? demandClasses.map(x => `&demandClasses=${x.value}` ).join('') : ''}${categories ? categories.map(x => `&categories=${x.value}` ).join('') : ''}` +
        `&calculationTypeId=${calculationType}`,
        successAction: GETFORECASTBIASCURRENT_SUCCESS,
        failureAction: GETFORECASTBIASCURRENT_FAILURE,
      },
    });
  },

  getMostAccurateMarketsLeaderboard: (periodType, fiscalMonth, quarter, yearFilter, forecastWeek, regions, demandClasses, demandClassQuery) => (dispatch) => {
    dispatch({
      type: GETMOSTACCURATEMARKETSLEADERBOARD_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `${FORECAST_ACCURACY_BASE_URL}/MostAccurateMarketsLeaderboard?periodType=${periodType}&month=${fiscalMonth}&quarter=${quarter}&year=${yearFilter}&forecastWeek=${forecastWeek}${regions ? regions.map(x => `&regionIds=${x.value}` ).join('') : ''}` +
        `${demandClasses ? demandClasses.map(x => `&demandClasses=${x.value}` ).join('') : ''}&demandClassQuery=${demandClassQuery}`,
        successAction: GETMOSTACCURATEMARKETSLEADERBOARD_SUCCESS,
        failureAction: GETMOSTACCURATEMARKETSLEADERBOARD_FAILURE,
      },
    });
  },

  getLeaderboardOpportunityAreas: (yearFilter, regions, demandClasses, calculationTypeId) => (dispatch) => {
    dispatch({
      type: GETOPPORTUNITYAREASLEADERBOARD_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `${FORECAST_ACCURACY_BASE_URL}/OpportunityAreasLeaderboard?year=${yearFilter}${regions ? regions.map(x => `&regionIds=${x.value}` ).join('') : ''}` +
        `${demandClasses ? demandClasses.map(x => `&demandClasses=${x.value}` ).join('') : ''}&calculationTypeId=${calculationTypeId}`,
        successAction: GETOPPORTUNITYAREASLEADERBOARD_SUCCESS,
        failureAction: GETOPPORTUNITYAREASLEADERBOARD_FAILURE,
      },
    });
  },

  getDemandClasses: (regionIds) => (dispatch) => {
    dispatch({
      type: '',
      http: {
        verb: 'GET',
        endpoint: `${FORECAST_ACCURACY_BASE_URL}/GetDemandClasses?${regionIds ? regionIds.map(x => `&regionIds=${x.value}` ).join('') : ''}`,
        successAction: GETDEMANDCLASSES_SUCCESS,
        failureAction: GETDEMANDCLASSES_FAILURE,
      },
    });
  },

  getProductCategories: (regionIds) => (dispatch) => {
    dispatch({
      type: '',
      http: {
        verb: 'GET',
        endpoint: `${FORECAST_ACCURACY_BASE_URL}/GetProductCategories?${regionIds ? regionIds.map(x => `&regionIds=${x.value}` ).join('') : ''}`,
        successAction: GETPRODUCTCATEGORIES_SUCCESS,
        failureAction: GETPRODUCTCATEGORIES_FAILURE,
      },
    });
  },
};
  
export default forecastAccuracyActions;
  