import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import forecastAccuracyActions from '../../../actions/forecastAccuracyActions';
import './ytdForecastAccuracy.scss';
import { connect } from 'react-redux';
import ForecastTile from '../Common/forecastTile';

const YtdForecastAccuracy = (props) => {
  const { productCategoryFilter, demandClassFilter, calculationType, regionFilter } = props;
  const [localStateRegion, setLocalStateRegion] = useState([]);

  useEffect(() => {
    if (!props.loading){
      props.getForecastAccuracyYtdData(regionFilter, demandClassFilter, productCategoryFilter, calculationType);
    }
  }, [demandClassFilter, productCategoryFilter, calculationType]);
  
  useEffect(() => {
    // Call the method only if regionFilter has changed
    if (JSON.stringify(regionFilter) !== JSON.stringify(localStateRegion)){
      props.getForecastAccuracyYtdData(regionFilter, null, null, calculationType);
      setLocalStateRegion(regionFilter); // Update the previous region filter
    }
  }, [regionFilter]);  
  const isValid = props.forecastAccuracyYtdData !== undefined; 

  return (
    <div className='carousel-container'>
      {props.forecastAccuracyYtdData.loading || !isValid && (<div className="app-spinner">
        <span className="spinner" />
      </div>)}

      {!props.forecastAccuracyYtdData.loading && isValid && (  
        <div>
          <div className='ytd-forecast-wrapper'>
            {props.forecastAccuracyYtdData && props.forecastAccuracyYtdData.map(f => 
              <ForecastTile key={f.title}
                title={f.title}
                value={f.value?.toFixed(2)} 
                positive={f.positive}
                differenceMetric={f.differenceValue?.toString()} 
              />,
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.forecastAccuracy.forecastYtdLoading,
    forecastAccuracyYtdData: state.forecastAccuracy.forecastAccuracyYtdData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getForecastAccuracyYtdData: (regionIds, demandClasses, categories, calculationType) => dispatch(forecastAccuracyActions.getForecastAccuracyYtdData(regionIds, demandClasses, categories, calculationType)),
  };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(YtdForecastAccuracy);