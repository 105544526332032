import {
  GETPRODUCTCATEGORIES_SUCCESS, GETPRODUCTCATEGORIES_FAILURE,
  GETMARKETFORECASTACCURACY_REQUEST,
  GETMARKETFORECASTACCURACY_SUCCESS,
  GETMARKETFORECASTACCURACY_FAILURE,
  GETFORECASTQUANTITYCHANGE_REQUEST,
  GETFORECASTQUANTITYCHANGE_SUCCESS,
  GETFORECASTQUANTITYCHANGE_FAILURE,
  GETFORECASTACCURACY_REQUEST,
  GETFORECASTACCURACYYTD_REQUEST,
  GETFORECASTACCURACYYTD_SUCCESS,
  GETFORECASTACCURACYYTD_FAILURE,
  GETCURRENTPERIODACCURACY_REQUEST,
  GETCURRENTPERIODACCURACY_SUCCESS,
  GETCURRENTPERIODACCURACY_FAILURE,
  GETFORECASTBIASYTD_REQUEST,
  GETFORECASTBIASYTD_SUCCESS,
  GETFORECASTBIASYTD_FAILURE,
  GETFORECASTBIASCURRENT_REQUEST,
  GETFORECASTBIASCURRENT_SUCCESS,
  GETFORECASTBIASCURRENT_FAILURE,
  GETFORECASTORDERIMPACT_REQUEST,
  GETFORECASTORDERIMPACT_SUCCESS,
  GETFORECASTORDERIMPACT_FAILURE,
  GETMOSTACCURATEMARKETSLEADERBOARD_REQUEST,
  GETMOSTACCURATEMARKETSLEADERBOARD_SUCCESS,
  GETMOSTACCURATEMARKETSLEADERBOARD_FAILURE,
  GETOPPORTUNITYAREASLEADERBOARD_REQUEST,
  GETOPPORTUNITYAREASLEADERBOARD_SUCCESS,
  GETOPPORTUNITYAREASLEADERBOARD_FAILURE,
  EXPORTFORECASTACCURACY_REQUEST,
  EXPORTFORECASTACCURACY_SUCCESS,
  EXPORTFORECASTACCURACY_FAILURE,
  GETDEMANDCLASSES_SUCCESS,
  GETDEMANDCLASSES_FAILURE } from '../actions/actionTypes';
import initialState from './initialState';
  
export const forecastAccuracyReducer = (

  state = initialState.forecastAccuracyState,
  action,
) => {
  switch (action.type) {

  case EXPORTFORECASTACCURACY_REQUEST: {
    return {
      ...state,
      exportLoading: true,
    };
  }
  case EXPORTFORECASTACCURACY_SUCCESS: {
    return {
      ...state,
      exportLoading: false,
      file: action.payload,
    };
  }

  case  EXPORTFORECASTACCURACY_FAILURE: {
    return {
      ...state,
      exportLoading: false,
      error: action.payload,
    };
  }

  case GETFORECASTACCURACY_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }

  case GETMARKETFORECASTACCURACY_REQUEST: {
    return {
      ...state,
      marketForecastLoading: true,
    };
  }
  case GETMARKETFORECASTACCURACY_SUCCESS: {
    return {
      ...state,
      marketForecastLoading: false,
      marketForecastAccuracyData: action.payload,
    };
  }
  case GETMARKETFORECASTACCURACY_FAILURE: {
    return {
      ...state,
      marketForecastLoading: false,
      error: action.payload,
    };
  }

  case GETFORECASTQUANTITYCHANGE_REQUEST: {
    return {
      ...state,
      forecastQuantityLoading: true,
    };
  }
  case GETFORECASTQUANTITYCHANGE_SUCCESS: {
    return {
      ...state,
      forecastQuantityLoading: false,
      forecastQuantityChangeData: action.payload,
    };
  }
  case GETFORECASTQUANTITYCHANGE_FAILURE: {
    return {
      ...state,
      forecastQuantityLoading: false,
      error: action.payload,
    };
  }

  case GETFORECASTACCURACYYTD_REQUEST: {
    return {
      ...state,
      forecastYtdLoading: true,
    };
  }
  case GETFORECASTACCURACYYTD_SUCCESS: {
    return {
      ...state,
      forecastYtdLoading: false,
      forecastAccuracyYtdData: action.payload,
    };
  }
  case GETFORECASTACCURACYYTD_FAILURE: {
    return {
      ...state,
      forecastYtdLoading: false,
      error: action.payload,
    };
  }
  
  case GETCURRENTPERIODACCURACY_REQUEST: {
    return {
      ...state,
      currentPeriodLoading: true,
    };
  }
  case GETCURRENTPERIODACCURACY_SUCCESS: {
    return {
      ...state,
      currentPeriodLoading: false,
      currentPeriodForecastAccuracyData: action.payload,
    };
  }
  case GETCURRENTPERIODACCURACY_FAILURE: {
    return {
      ...state,
      currentPeriodLoading: false,
      error: action.payload,
    };
  }

  case GETFORECASTORDERIMPACT_REQUEST: {
    return {
      ...state,
      orderImpactLoading: true,
    };
  }
  case GETFORECASTORDERIMPACT_SUCCESS: {
    return {
      ...state,
      orderImpactLoading: false,
      forecastOrderImpactData: action.payload,
    };
  }
  case GETFORECASTORDERIMPACT_FAILURE: {
    return {
      ...state,
      orderImpactLoading: false,
      error: action.payload,
    };
  }

  case GETFORECASTBIASYTD_REQUEST: {
    return {
      ...state,
      biasYtdLoading: true,
    };
  }
  case GETFORECASTBIASYTD_SUCCESS: {
    return {
      ...state,
      biasYtdLoading: false,
      forecastBiasYtdData: action.payload,
    };
  }
  case GETFORECASTBIASYTD_FAILURE: {
    return {
      ...state,
      biasYtdLoading: false,
      error: action.payload,
    };
  }

  case GETFORECASTBIASCURRENT_REQUEST: {
    return {
      ...state,
      biasCurrentLoading: true,
    };
  }
  case GETFORECASTBIASCURRENT_SUCCESS: {
    return {
      ...state,
      biasCurrentLoading: false,
      forecastBiasCurrentData: action.payload,
    };
  }
  case GETFORECASTBIASCURRENT_FAILURE: {
    return {
      ...state,
      biasCurrentLoading: false,
      error: action.payload,
    };
  }

  case GETMOSTACCURATEMARKETSLEADERBOARD_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case GETMOSTACCURATEMARKETSLEADERBOARD_SUCCESS: {
    return {
      ...state,
      loading: false,
      mostAccurateMarketsData: action.payload,
    };
  }
  case GETMOSTACCURATEMARKETSLEADERBOARD_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }

  case GETOPPORTUNITYAREASLEADERBOARD_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case GETOPPORTUNITYAREASLEADERBOARD_SUCCESS: {
    return {
      ...state,
      loading: false,
      opportunityAreasData: action.payload,
    };
  }
  case GETOPPORTUNITYAREASLEADERBOARD_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }

  case GETDEMANDCLASSES_SUCCESS: {
    return {
      ...state,
      loading: false,
      demandClassData: action.payload,
    };
  }

  case GETDEMANDCLASSES_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }

  case GETPRODUCTCATEGORIES_SUCCESS: {
    return {
      ...state,
      loading: false,
      productCategoriesData: action.payload,
    };
  }

  case  GETPRODUCTCATEGORIES_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }

  default:
    return state;
  }
};
export default forecastAccuracyReducer;
  