import React, { useState, useEffect } from 'react';
import forecastAccuracyActions from '../../../actions/forecastAccuracyActions';
import { connect } from 'react-redux';
import CustomLineChart from '../CustomCharts/CustomLineChart';
import chroma from 'chroma-js';
import 'react-datepicker/dist/react-datepicker.css';
import { QuantityTypeEnum } from './types/quantityTypeEnum';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

const ForecastQuantityChange = (props) =>{
  const [transformedData, setTransformedData] = useState([]);
  const [lines, setLines] = useState([]);
  const { productCategoryFilter, demandClassFilter, calculationType, regionFilter, periodType, fiscalMonth, quarter, yearFilter, forecastWeekDate } = props;
  const [localStateRegion, setLocalStateRegion] = useState([]);
  const [quantityType, setQuantityType] = useState(QuantityTypeEnum.EA);

  useEffect(() => {
    if (!props.loading){
      props.getForecastQuantityChange(
        periodType, fiscalMonth, quarter, yearFilter,
        new Date(forecastWeekDate.getTime() - forecastWeekDate.getTimezoneOffset() * 60000).toISOString(),
        productCategoryFilter ? productCategoryFilter.map(x => x.value) : null,
        demandClassFilter ? demandClassFilter.map(x => x.value) : null,
        regionFilter ? regionFilter.map(x => x.value) : null,
        quantityType,
      );
    }
  }, [periodType, fiscalMonth, quarter, yearFilter, forecastWeekDate, productCategoryFilter, demandClassFilter, calculationType, quantityType]);

  useEffect(() => {
    // Call the method only if regionFilter has changed
    if (JSON.stringify(regionFilter) !== JSON.stringify(localStateRegion)){
      props.getForecastQuantityChange(
        periodType, fiscalMonth, quarter, yearFilter,
        new Date(forecastWeekDate.getTime() - forecastWeekDate.getTimezoneOffset() * 60000).toISOString(),
        null,
        null,
        regionFilter ? regionFilter.map(x => x.value) : null,
        quantityType,
      );
      setLocalStateRegion(regionFilter);
    }
  }, [regionFilter]);  

  useEffect(() => {
    const newData = props.forecastQuantityChangeData?.map((lag) => {
      return {
        name: lag.name,
        ...lag.demandClasses,
      };
    });

    setTransformedData(newData);

    const getDistinctColors = (count) => {
      return chroma.scale('Set2').colors(count);
    };

    const allDemandClasses = props.forecastQuantityChangeData
      ? props.forecastQuantityChangeData.reduce((dcs, lagData) => {
        if (Object.keys(lagData.demandClasses).length > 0) {
          Object.keys(lagData.demandClasses).forEach(dc => {
            if (!dcs.includes(dc)) {
              dcs.push(dc);
            }
          });
        }
        return dcs;
      }, [])
      : [];

    const distinctColors = getDistinctColors(allDemandClasses.length);

    const lines = allDemandClasses.map((dc, index) => ({
      dataKey: dc,
      strokeColor: distinctColors[index % distinctColors.length],
    }));

    setLines(lines);
  }, [props.forecastQuantityChangeData]);

  return <>
    <div
      className='chart-container'
      style={{ display: 'block' }}>
      {props.loading ? (
        <div className="app-spinner">
          <span className="spinner" />
        </div>
      ) : (
        <>
          <CustomLineChart data={transformedData}
            lines={lines}
            chartTitle={'Forecast Quantity'}
            showsPercentage={true}
            dataKey={'name'}
            width={'100%'} />
          <FormControl style={{ alignItems: 'center' , width: '100%' }}>
            <RadioGroup
              row
              value={quantityType}
              onChange={(e) => setQuantityType(parseInt(e.target.value))}
            >
              <FormControlLabel style={{ justifyContent: 'center' }}
                value={QuantityTypeEnum.EA}
                control={<Radio color="success" />}
                label="Qty (EA)" />
              <FormControlLabel style={{ justifyContent: 'center' }}
                value={QuantityTypeEnum.PLT}
                control={<Radio color="success"/>}
                label="Qty (PLT)" />
              <FormControlLabel style={{ justifyContent: 'center' }}
                value={QuantityTypeEnum.VALUE}
                control={<Radio color="success" />}
                label="Value (£)" />
            </RadioGroup>
          </FormControl>
        </>
      )}
    </div>
  </>;
};

const mapStateToProps = (state) => {
  return {
    forecastQuantityChangeData: state.forecastAccuracy.forecastQuantityChangeData,
    loading: state.forecastAccuracy.forecastQuantityLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getForecastQuantityChange: (periodType, fiscalMonth, quarter, yearFilter, forecastWeek, productCategories, demandClasses, regionIds, quantityType) => dispatch(forecastAccuracyActions.getForecastQuantityChange(periodType, fiscalMonth, quarter, yearFilter, forecastWeek, productCategories, demandClasses, regionIds, quantityType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForecastQuantityChange);