import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import forecastAccuracyActions from '../../../actions/forecastAccuracyActions';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import { Bar, BarChart, CartesianGrid, Tooltip, ReferenceLine, ResponsiveContainer, XAxis, YAxis, Cell } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="custom-tooltip"
        style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}>
        <p className="label">{`Name: ${label}`}</p>
        {payload.map((entry, index) => (
          <p key={`item-${index}`}
            style={{ color: entry.color }}>
            {`${data.name}: ${entry.value}%`}
          </p>
        ))}
        {data.toleranceUsedAbove !== null && data.toleranceUsedBelow !== null && (
          <p>
            <span style={{ color: '#02a862' }}>{`+${data.toleranceUsedAbove}/`}</span>
            <span style={{ color: '#d3372a' }}>{`-${data.toleranceUsedBelow}`}</span>
          </p>
        )}
      </div>
    );
  }

  return null;
};

const ForecastOrderImpactChart = (props) => {
  const { productCategoryFilter, demandClassFilter, regionFilter, forecastWeekDate, periodType, yearFilter, quarter, fiscalMonth } = props;
  const [localStateRegion, setLocalStateRegion] = useState([]);

  useEffect(() => {
    if (!props.loading){
      props.getForecastOrderImpactData(periodType, fiscalMonth, quarter, yearFilter, new Date(forecastWeekDate.getTime() - forecastWeekDate.getTimezoneOffset() * 60000).toISOString(), regionFilter, demandClassFilter, productCategoryFilter);
    }
  }, [periodType, fiscalMonth, quarter, yearFilter, forecastWeekDate, demandClassFilter, productCategoryFilter]);

  useEffect(() => {
    // Call the method only if regionFilter has changed
    if (JSON.stringify(regionFilter) !== JSON.stringify(localStateRegion)){
      props.getForecastOrderImpactData(periodType, fiscalMonth, quarter, yearFilter, new Date(forecastWeekDate.getTime() - forecastWeekDate.getTimezoneOffset() * 60000).toISOString(), regionFilter, null, null);
      setLocalStateRegion(regionFilter); // Update the previous region filter
    }
  }, [regionFilter]);  

  const prepareWaterfallData = (data) => {
    let cumulativeValue = 0;
    return data.map(item => {
      let uv;
      if (item.name === 'Active Forecast' || item.name === 'Submitted Forecast') {
        uv = item.forecast !== null ? item.forecast : 0;
        cumulativeValue = uv; 
      } else {
        uv = item.positive !== null ? 
          item.positive : (item.negative !== null ? item.negative : 0);
        cumulativeValue += uv;
      }
      const pv = cumulativeValue - uv;
      return { ...item, uv, pv };
    });
  };

  const waterfallData = prepareWaterfallData(props.forecastOrderImpactData);

  return (
    <div>
      <div className='chart-container'>
        {props.forecastOrderImpactData.loading ? (
          <div className="app-spinner">
            <span className="spinner" />
          </div>
        ) : (
          <>
            <ResponsiveContainer width={'100%'}
              height={450}>
              <BarChart
                width={200}
                height={250}
                data={waterfallData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis tickFormatter={(number) => `${number}%`} />
                <Tooltip content={<CustomTooltip />} />
                <ReferenceLine y={0}
                  stroke="#000" />

                <Bar dataKey="pv"
                  stackId="a"
                  fill="transparent" />
                <Bar dataKey="uv"
                  stackId="a"
                  fill="#82ca9d">
                  {waterfallData.map((item, index) => {
                    if (item.uv < 0 || item.name === 'Error') {
                      return <Cell key={index}
                        fill="#B22222" />;
                    }
                    if (item.name === 'Active Forecast' || item.name === 'Submitted Forecast') {
                      return <Cell key={index}
                        fill="#c3c3c3" />;
                    }
                    return <Cell key={index}
                      fill="#228B22" />;
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer></>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.forecastAccuracy.biasCurrentLoading,
    forecastOrderImpactData: state.forecastAccuracy.forecastOrderImpactData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getForecastOrderImpactData: (periodType, fiscalMonth, quarter, yearFilter, forecastWeek, regionIds, demandClasses, categories) => dispatch(forecastAccuracyActions.getForecastOrderImpactData(periodType, fiscalMonth, quarter, yearFilter, forecastWeek, regionIds, demandClasses, categories)),
  };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(ForecastOrderImpactChart);