/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import CustomLineChart from '../CustomCharts/CustomLineChart';
import Table from '../CustomTables/CustomTable';
import './ordermetrics.scss';
import { connect } from 'react-redux';
import forecastPerformanceDashboardActions from '../../../actions/forecastPerformanceDashboardActions';
import { doDataExport } from '../DemandAndSupply/CarouselCharts/CommonCarouselFunctions';
import MarketMultiSelectFilter from '../CustomFilters/MultiSelect/MarketMultiSelectFilter';
import { TimeFilterEnum } from '../Common/Enums/TimeFilterEnum';
import { ExportChart } from '../Common/Enums/ExportChart';
import PeriodMultiSelectFilter from '../CustomFilters/MultiSelect/PeriodMultiSelectFilter';

const OrderMetrics = (props) => {
  const marketIds = props.mbuData.map(market => market.marketId);

  const [loading, setLoading] = useState(true);
  const [selectedMarkets, setSelectedMarkets] = useState(marketIds);
  const [periodFilterValue, setPeriodFilterValue] = useState(TimeFilterEnum.YTD);
  const [selectedMonths, setSelectedMonths] = useState([]);

  const handlePeriodChange = (year) => {
    setPeriodFilterValue(year);
  };


  const handleMonthsChange = (selectedMonths) => {
    setSelectedMonths(selectedMonths);
  };

  const handleMarketFilterChange = (selectedMarkets) => {
    setSelectedMarkets(selectedMarkets);
  };
  
  useEffect(() => {
    const fetchData = async () => {
      await props.getOrderMetricsChartData(periodFilterValue, selectedMonths, selectedMarkets);
      setLoading(false);
    };
    fetchData();
  }, []);

  const isValid = props.orderMetricsData.orderMetricTableData !== undefined && props.orderMetricsData.orderMetricChartData != undefined; 
   
  const charts = [
    {
      lines: [
        { name: 'Total Orders', dataKey: 'totalOrders', strokeColor: '#8884d8' },
        { name: 'Total Lines', dataKey: 'totalLines', strokeColor: '#82ca9d' },
      ],
      title: 'Total Orders vs Total Lines',
      width:'30%',
    },
    {
      lines: [
        { name: 'Forecast Changes', dataKey: 'forecastChanges', strokeColor: '#8884d8' },
      ],
      title: 'Total Number of Forecast Changes',
      width:'30%',
    },
    {
      lines: [
        { name: 'Emergency Orders', dataKey: 'emergencyOrders', strokeColor: '#8884d8' },
      ],
      title: 'Total Number of Emergency Orders',
      width:'30%',
    },
  ];

  const sortedMonths = ['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'];
  const sortByMonths = (a, b) => {
    return monthIndices[a.name] - monthIndices[b.name];
  };

  const monthIndices = sortedMonths.reduce((acc, month, index) => {
    acc[month] = index;
    return acc;
  }, {});
   
  const data  = isValid && props.orderMetricsData.orderMetricChartData.sort(sortByMonths);
  const tableHeaders = ['Order Metrics', 'Month To Date', 'Year To Date','Previous Year To Date'];
  const hasChartData = isValid && props.orderMetricsData.orderMetricChartData.length > 0;

  const handleSearchButtonClick = () => {
    props.getOrderMetricsChartData(periodFilterValue, selectedMonths, selectedMarkets);
  };

  const handleClearFilterClick = () => {
    var newPeriod = TimeFilterEnum.YTD;
    var newMonths = [];
    setSelectedMarkets(marketIds);
    setPeriodFilterValue(newPeriod);
    setSelectedMonths(newMonths);
    props.getOrderMetricsChartData(newPeriod, newMonths, marketIds);
  };

  const handleExportSummary = () => {
    props.exportRawData(ExportChart.OrderMetrics,periodFilterValue,selectedMonths,selectedMarkets);
  };

  const transformedData = props.orderMetricsData?.orderMetricTableData?.map(item => {
    return {
      'Order Metrics': item.orderMetrics,
      'Month To Date': item.monthToDate,
      'Year To Date': item.yearToDate,
      'Previous Year To Date': item.previousYear,
    };
  });

  const months = [
    { key: 10, value: 'October' },
    { key: 11, value: 'November' },
    { key: 12, value: 'December' },
    { key: 1, value: 'January' },
    { key: 2, value: 'February' },
    { key: 3, value: 'March' },
    { key: 4, value: 'April' },
    { key: 5, value: 'May' },
    { key: 6, value: 'June' },
    { key: 7, value: 'July' },
    { key: 8, value: 'August' },
    { key: 9, value: 'September' },
  ];

  const filters = [
    { key: 'Period', value: periodFilterValue },
    { key: 'Selected Months', value: selectedMonths.map(id => months.find(month => month.key === id)?.value).join(', ') },
    { key: 'Selected Markets', value: selectedMarkets.map(id => props.mbuData?.find(m => m.marketId === id)?.name).join(', ') },
  ];

  return <div>
    {loading || !isValid && (
      <div className="app-spinner">
        <span className="spinner" />
      </div>
    )}
    
    {!loading && isValid && (
      <>
        <div className="group-details accordion download-container summary forecast-export">
          <div className="accordion-header">
            <h3>Download Summary</h3>
          </div>
          <div className="accordion-body horizontal-flex order-fields">
            <div>
              <button
                className="action export"
                type="button"
                title="Export"
                disabled={false}
                onClick={() => handleExportSummary()}
              >
                <div
                  className="icon icon-download-light"
                  alt="Export"
                />
              </button>
            </div>
          </div>
        </div>
        <div className='orderMetrics-filter-container'>
          <MarketMultiSelectFilter 
            mbuList={props.mbuData}
            selectedMarkets={selectedMarkets}
            onMarketFilterChange={handleMarketFilterChange}/>
          <PeriodMultiSelectFilter        
            periodFilter={periodFilterValue}
            selectedMonths={selectedMonths}
            onPeriodFilterChange={handlePeriodChange}
            onSelectedMonths={handleMonthsChange}
            orderMetrics={true} />
        </div>

        <div className="kpi-filter-action">
          <button
            type="button"
            className="action"
            onClick={() => handleSearchButtonClick()}
          >
          Search
          </button>
          {<button
            type="clear"
            className="action default"
            onClick={() => handleClearFilterClick()}
          >
          Clear Filter
          </button>}
        </div>

        <div className='order-table-container'>
          <Table headers={tableHeaders}
            data={transformedData} />
        </div>

        {hasChartData &&
        <><div id='order-metrics'
          className='chart-container'>
          {charts.map((chart) => (
            <div
              className='chart'>
              <CustomLineChart
                data={data}
                chartTitle={chart.title}
                lines={chart.lines}
                width={chart.width} />
            </div>
          ))}
          
        </div><div className='export-button-style'>
          <button type="button"
            className="action export"
            onClick={() => doDataExport('Order Metrics', 'order-metrics', 'chart-container', filters)}>Export Chart</button>
        </div>

        </>
        }

        {!hasChartData &&
        <div>
          <p className='text-style'>There is no data available</p>
        </div>}
        

      </>)}
  </div>;
};

const mapStateToProps = (state) => {
  return {
    orderMetricsData: state.forecastPerformanceDashboard.orderMetricsData,
  };
};
 
const mapDispatchToProps = (dispatch) => {
  return {
    getOrderMetricsChartData: (timeFilter, selectedMonths, selectedMarkets) => 
      dispatch(forecastPerformanceDashboardActions.getOrderMetricsChartData(timeFilter, selectedMonths, selectedMarkets)),

    exportRawData: (exportChart, timeFilter, selectedMonths, selectedMarkets) => 
      dispatch(forecastPerformanceDashboardActions.exportRawData(exportChart, timeFilter, selectedMonths, selectedMarkets)),
  };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(OrderMetrics);