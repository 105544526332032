// Restore auth state
const user = localStorage.getItem('user');
let authState = user && JSON.parse(user);

if (
  !authState // no auth state
  || !authState.isAuthenticated // Not logged in
  || !authState.expiry // No expiry
  || authState.expiry <= new Date().getTime() + 15000 // Will expire in the next 15 seconds.
) {
  // reset state
  authState = {
    isAuthenticated: false,
    isFetching: false,
    errorMessage: '',
    isFetching_Stores: false,
    isLoaded_Stores: false,
    storeList: [],
    updateUser: false,
    updatedUser: false,
    displayConnect: true,
  };
}

// Restore module nesting
let modulePath = localStorage.getItem('modulePath');
modulePath = modulePath && JSON.parse(modulePath);

if (!modulePath) {
  modulePath = [];
}

export default {
  authState,
  moduleState: {
    isFetching: false,
    isLoaded: false,
    currentModule: null,
    moduleContents: [],
    modulePath: null,
  },
  userManagementState: {
    users: { isFetching: false, errorMessage: '', userList: [] },
    moduleGroups: { isFetching: false, errorMessage: '', groupList: [] },
    storeGroups: { isFetching: false, errorMessage: '', groupList: [] },
    currentUser: { isFetching: false, errorMessage: '', userDetails: {} },
    currentModuleGroup: {
      isFetching: false,
      errorMessage: '',
      groupDetails: {},
    },
    currentStoreGroup: {
      isFetching: false,
      errorMessage: '',
      groupDetails: {},
    },
  },
  notificationState: {
    isFetching: false,
    errorMessage: '',
    allPopups: [],
    alertPopups: [],
    adminAlerts: [],
    currentAdminAlert: {},
  },
  emailDocumentManagementState: {
    errorMessage: '',
    isFetching:false,
    entries: [],
    folders:[],
    entry: {},
  },
  feedbackState: {},
  navigationState: {
    history: [],
    navigationCount: 0,
  },
  forecastChangeRequestState: {
    checkingItem: false,
    checkedItem: false,
    item: {},
  },
  emailDistributionListState: {
    isFetching: false,
    isLoaded: false,
    detail: [],
    emails: [],
    error: '',
  },
  myDocumentsState: {
    isFetching: false,
    errorMessage: '',
    myDocumentList: [],
    myCurrentDocument: {},
  },
  documentManagementState: {
    isFetching: false,
    errorMessage: '',
    documentList: [],
  },
  overdueInvoiceState: {
    isFetching: false,
    isLoaded: false,
    overdueInvoiceList: [],
  },
  rslPerformanceState: {
    isFetching: false,
    isLoaded: false,
    rslPerformanceList: [],
  },
  itemCatalogueState: {
    isFetching: false,
    isLoaded: false,
    maxPages: undefined,
    rowCount: undefined,
    itemList: [],
    itemDetail: {},
  },
  itemPricingState: {
    isFetching: false,
    isLoaded: false,
    maxPages: undefined,
    rowCount: undefined,
    itemList: [],
  },
  vasListState: {
    isFetching: false,
    isLoaded: false,
    vasLists: [],
    vasDetails: [],
  },
  bookingFormState: {
    isUploading: false,
    isLoaded: false,
    bookingForms: [],
  },
  masterFileState: { isFetching: false, isLoaded: false, errorMessage: '' },
  bulkApllicationUploadState: { isFetching: false, isLoaded: false, errorMessage: '' },
  customerRequirementsState: {
    isFetching: false,
    isLoaded: false,
    customerRequirements: {},
    errorMessage: '',
    isSubmitting: false,
    isSubmitted: false,
  },
  customerRequirementsOdsState: {
    isFetching: false,
    isLoaded: false,
    customerRequirementsOds: {},
    errorMessage: '',
    isSaving: false,
    isSaved: false,
    documentLoaded:false,
  },
  platformUsageState: {
    moduleData: {},
    userData: {},
    actionData: {},
  },
  messageState: {
    connected: false,
    chatRooms: [],
    currentChatRoom: {
      conversations: [],
      queue: [],
    },
  },
  messageArchiveState: {
    messageHistory: {},
    messageDetail: {},
  },
  siteIDApplicationsState: {
    data: [],
    isFetching: false,
    isLoaded: false,
  },
  siteIDInactiveApplicationsState: {
    data: [],
    isFetching: false,
    isLoaded: false,
  },
  siteIDApplicationDetailState: {},
  siteIDCompanyState: {
    companies: { isFetching: false, errorMessage: '', companyList: [] },
    currentCompany: { isFetching: false, errorMessage: '', companyDetails: {} },
  },
  siteIDEquityStoreState: {
    isLoaded: false,
    equityStores: [],
    errorMessage: '',
  },
  siteIDOpenStoreState: {
    isLoaded: false,
    data: [],
    errorMessage: '',
  },
  siteIDDriveThroughState: {
    isLoaded: false,
    marketPlan: [],
    errorMessage: '',
  },
  siteIDDefaultResponseState: {
    isLoading: false,
    isLoaded: false,
    error: undefined,
    defaultResponseList: [],
    selectedResponse: {},
    statuses: [],
  },
  siteIDReportingState: {
    referenceData: {},
    reportData: {},
  },
  siteIDDtmpFileState: { isFetching: false, isLoaded: false, errorMessage: '' },
  orderTrackerState: {
    isLoading: false,
    isLoaded: false,
    isContactSubmitted: false,
    error: undefined,
    searchResults: [],
    order: undefined,
    bulkOrderList: [],
    dashboard: undefined,
    dashboardCustomers: [],
    customers: [],
    customerStores: [],
  },
  connectConfigurationState: {
    isLoading: false,
    isLoaded: false,
    isSaved: false,
    error: undefined,
    configurationItems: [],
    greenLowerLimit: 0,
    redUpperLimit: 0,
  },
  connectContentManagementState: {
    isLoading: false,
    isLoaded: false,
    isSaved: false,
    error: undefined,
    labels: [],
    templates: [],
    audiences: [],
    categories: [],
    content: {},
    backgrounds: [],
    assets: [],
  },
  connectAssetManagementState: {
    isLoadingAssets: false,
    isLoadedAssets: false,
    isLoadingCategories: false,
    isLoadedCategory: false,
    error: undefined,
    assetCategoryList: [],
    assetList: [],
  },
  connectContentState: {
    isLoading: false,
    isLoaded: false,
    error: undefined,
    contentList: [],
    labelList: [],
    pageNumber: 1,
    pageSize: 25,
    totalRecords: 0,
    displayContent: [],
    config: { configurationItems:[] },
    targetTypeData: [],
    isDisplayAdmin: false,
  },
  connectLabelState: {
    isLoading: false,
    isLoaded: false,
    error: undefined,
    labelList: [],
    selectedLabel: {},
  },
  connectSideBarState: {
    open: false,
    mode: '',
    isMenuOpen: false,
  },
  connectTaskState: {
    isFetching: false,
    errorMessage: '',
    allPopups: [],
    alertPopups: [],
    adminAlerts: [],
    currentAdminAlert: {},
  },
  connectWeatherState: {
    isLoading: false,
    isLoaded: false,
    error: undefined,
    weather: {},
  },
  connectEventsState: {
    isLoading: false,
    isLoaded: false,
    error: undefined,
    events: {},
  },
  connectReportingState: {
    isLoading: false,
    isLoaded: false,
    error: undefined,
    data: {},
    notificationData: {},
    taskData: {},
  },
  kpiDashboardState: {
    loading: false,
    error: '',
    inFullDashboard: {},
    configurations: [],
    failedItems: [],
  },
  posItemNumbeRequestState: {
    isFetching: false,
    isLoaded: false,
    maxPages: undefined,
    rowCount: undefined,
    itemList: [],
    itemDetail: null,
    itemType: [],
    productPlatform:[],
    productSubCategory: [],
    temperature: [],
    uom: [],
    storeGroups: [],
    statuses: [],
    isSaving: false,
    isSaved: false,
  },
  posItemNumbeRequestAdminState: {
    isFetching: false,
    isLoaded: false,
    maxPages: undefined,
    rowCount: undefined,
    adminItemList: [],
    adminItemDetail: {},
  },

  posMdioState: {
    isFetching: false,
    isLoaded: false,
    maxPages: undefined,
    rowCount: undefined,
    isSaving: false,
    isSaved: false,
    sbuxItemDetail: '',
    sbuxItemNumber:  '',
    mdioList: [],
    mdioDetail: {},
  },

  posItemEmailConfigState: {
    isFetching: false,
    isLoaded: false,
    isSaving: false,
    isSaved: false,
    distributionLists: [],
    itemTypes: [],
    errorMessage: '',
  },
  posItemCatalogueState: {
    isFetching: false,
    isLoaded: false,
    maxPages: undefined,
    rowCount: undefined,
    catalogueItemList: [],
    catalogueItemDetail: {},
    uoms: [],
  },
  apacOrderDocumentState: {
    isLoading: false,
    isLoaded: false,
    isSaving: false,
    isSaved: false,
    isUploading: false,
    isUpoaded: false,
    isDeleting: false,
    isDeleted: false,
    documents: [],
  },
  
  forecastAccuracyState: {
    loading: false,
    error: '',
    forecastOrderImpactData: [],
    forecastChangeRequestsData: [],
    currentPeriodForecastAccuracyData: [],
    forecastBiasCurrentData: [],
    forecastQuantityChangeData: [],
    forecastAccuracyYtdData: [],
    forecastBiasYtdData: [],
    mostAccurateMarketsData: [],
    demandClassData: [],
    productCategoriesData: [],
  },

  forecastPerformanceDashboardState: {
    loading: false,
    error: '',
    orderMetricsData: [],
    shipmentPerfomanceData: [],
    emergencyOrdersData: [],
    forecastChangeRequestsData: [],
    kpiData: [],
    mapeYearlyChartData: [],
    mapeByCategoryChartData: [],
    mapeByTopTenItemsChartData: [],
    forecastVsOrderData: [],
    mbuData: [],
    productCategoriesData: [],
    kpiReferenceData: [],
  },
};
